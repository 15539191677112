import React, { useEffect, useRef, useState } from "react";
import "./sidebar.collapsible.item.component.scss";
import classNames from "classnames";
import { useSidebar } from "../sidebar.provider.component/sidebar.provider.component";
import { useClickedOutside } from "globals/helpers/hook.helper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";

interface SidebarCollapsibleItemProps {
  title: string;
  icon: any;
  children: React.ReactNode;
  paths?: string[];
}

const SidebarCollapsibleItem = ({
  title,
  icon,
  children,
  paths,
}: SidebarCollapsibleItemProps): JSX.Element => {
  const { isCollapsed } = useSidebar();

  const itemRef = useRef<HTMLDivElement>(null);
  useClickedOutside(itemRef, () => {
    setItemActive(false);
    setItemOpen(false);
  });

  const popupRef = useRef<HTMLDivElement>(null);
  useClickedOutside(popupRef, () => {
    if (isCollapsed) setItemOpen(false);
  });

  const [isItemOpen, setItemOpen] = useState(false);
  const [isItemActive, setItemActive] = useState(false);
  const [oldCollabsedState, setOldCollabsedState] = useState(isCollapsed);

  useEffect(() => {
    if (oldCollabsedState !== isCollapsed && isCollapsed && isItemOpen) {
      setItemOpen(false);
    }

    setOldCollabsedState(isCollapsed);
  }, [isCollapsed]);

  const handleClickItem = (): void => {
    setItemActive(true);
    setItemOpen(!isItemOpen);
  };

  const sidebarItemClassName = classNames({
    "sidebar-item": true,
    "sidebar-item--active": isItemActive,
  });

  return (
    <div ref={itemRef}>
      <Column key={title}>
        <Row
          className={sidebarItemClassName}
          alignItems="center"
          onClick={handleClickItem}
        >
          <Row
            alignItems="center"
            justifyContent={isCollapsed ? "center" : undefined}
          >
            <FontAwesomeIcon className="sidebar-item-icon" icon={icon} />
            {!isCollapsed && (
              <TitleText className="sidebar-item-label ml-10">
                {title}
              </TitleText>
            )}
          </Row>
          {!isCollapsed && (
            <FontAwesomeIcon
              className="sidebar-item-arrow"
              icon={isItemOpen ? faAngleUp : faAngleDown}
            />
          )}
        </Row>
        <div
          ref={popupRef}
          className={classNames("sidebar-submenu", {
            "sidebar-submenu--open": isItemOpen,
            "sidebar-submenu--collapsed": isCollapsed && isItemOpen,
          })}
          onClick={() => {
            if (isCollapsed) {
              setItemOpen(false);
            }
          }}
        >
          {children}
        </div>
      </Column>
    </div>
  );
};

export default SidebarCollapsibleItem;
