import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { faXmark, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import Headline from "../../text.components/headline.component/headline.component";
import Column from "../../general.compoenents/column.component/column.component";
import "./page.intro.card.component.scss";
import Row from "../../general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import { LargeText } from "components/text.components/large.text.component/large.text.component";
import { Trans } from "react-i18next";

const CLOSED_PAGE_INTROS = "closed-page-intros";

interface PageIntroCardProps {
  title: string;
  collapsedTitle: string;
  description: string;
  image?: any;
  imagePosition?: string;
  imageClassName?: string;
  wrapperClassName?: string;
  disableMargin?: boolean;
  id: string;
  renderButtons?: () => JSX.Element;
}

const PageIntroCard = ({
  title,
  collapsedTitle,
  description,
  image,
  imagePosition,
  imageClassName,
  wrapperClassName,
  disableMargin = false,
  id,
  renderButtons,
}: PageIntroCardProps): JSX.Element => {
  // get pageIntrosIds from the local storage and parse the value
  const localStorageItem = localStorage.getItem(CLOSED_PAGE_INTROS);
  let pageIntrosIds: string[] = [];
  let isIdAlreadySet = false;

  if (localStorageItem) {
    pageIntrosIds = JSON.parse(localStorageItem);
    if (pageIntrosIds.length > 0) {
      // check if pageIntroId is marked as collapsed
      isIdAlreadySet = pageIntrosIds.includes(id);
    }
  }

  const [isCollapsed, setIsCollapsed] = useState(isIdAlreadySet);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const introWrapperClassName = classNames(
    {
      "page-intro-card": true,
      "page-intro-card-disable-margin": disableMargin,
      "page-intro-card-collapsed": isCollapsed,
    },

    wrapperClassName
  );

  const introCardClassName = classNames(
    {
      "page-intro-card-image": true,
    },
    imageClassName
  );

  useEffect(() => {
    // to prevent reassign
    if (!initialLoadDone) {
      setInitialLoadDone(true);
      return;
    }

    // get pageIntrosIds from the local storage and parse the value
    const localStorageItem = localStorage.getItem(CLOSED_PAGE_INTROS);
    let pageIntrosIds: string[] = [];

    if (localStorageItem) {
      pageIntrosIds = JSON.parse(localStorageItem);
    }

    const isIdAlreadySet = pageIntrosIds.includes(id);
    let result = [...pageIntrosIds];

    if (isCollapsed && !isIdAlreadySet) {
      // pageIntroId should be added to the local storage. intro is collapsed
      result = [...result, id];
    } else if (!isCollapsed) {
      // pageIntroId needs to be removed from the local storage. intro is expanded
      result = result.filter((pageIntroId) => pageIntroId !== id);
    }

    localStorage.setItem(CLOSED_PAGE_INTROS, JSON.stringify(result));
  }, [isCollapsed]);

  return (
    <div className={introWrapperClassName}>
      {isCollapsed && (
        <Row alignItems="center">
          <LargeText className="m-20">
            <Trans i18nKey={collapsedTitle} />
          </LargeText>
          {renderButtons?.()}
        </Row>
      )}
      <Column
        className="page-intro-card-content"
        justifyContent="center"
        alignItems="flex-start"
        style={{
          opacity: isCollapsed ? "0" : "1",
          transition: "opacity 0.45s linear",
          visibility: isCollapsed ? "hidden" : "visible",
          width: isCollapsed ? 0 : "auto",
        }}
        gap={10}
      >
        <Headline>{title}</Headline>
        <RunningText>{description}</RunningText>
        <Row gap={15}>{renderButtons?.()}</Row>
      </Column>
      {image && (
        <img
          className={introCardClassName}
          src={image}
          alt={title}
          style={{
            objectPosition: imagePosition ?? "center",
            opacity: isCollapsed ? "0" : "1",
            transition: "opacity 0.45s linear",
            visibility: isCollapsed ? "hidden" : "visible",
            width: isCollapsed ? 0 : "auto",
          }}
        />
      )}
      <IconButton
        icon={isCollapsed ? faChevronDown : faXmark}
        className="mt-20 mr-20"
        onClick={() => {
          setIsCollapsed((oldIsCollapsed) => !oldIsCollapsed);
        }}
      />
    </div>
  );
};

export default PageIntroCard;
