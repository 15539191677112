/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { contentLibrarySchema } from "schemas/content.library.schemas/content.library.schema";
import { languageSchema } from "schemas/language.string.schema";
import { propertySchema } from "schemas/property.schemas/property.schema";
import {
  Localization,
  studioSchema,
} from "schemas/studio.schemas/studio.schema";
import { System } from "schemas/system.schema";
import { userSchema } from "schemas/user.schemas/user.schema";
import * as yup from "yup";

export const infoDataSchema = (languages: Localization[]) =>
  yup.object().shape({
    label: languageSchema(languages).required(),
    description: languageSchema(languages).notRequired(),
  });

export interface InfoData
  extends yup.InferType<ReturnType<typeof infoDataSchema>> {}

export const infoListSchema = (languages: Localization[]) =>
  yup.object().shape({
    label: languageSchema(languages).required(), // TODO
    data: yup.array().of(infoDataSchema(languages)).required(),
  });

export interface InfoList
  extends yup.InferType<ReturnType<typeof infoListSchema>> {
  id?: string;
}

export const muscleGroupSchema = yup.object().shape({
  agonist: yup
    .array()
    .of(propertySchema([]))
    .required("Agonist ist erforderlich"),
  synergist: yup
    .array()
    .of(propertySchema([]))
    .required("Synergist ist erforderlich"),
  antagonist: yup
    .array()
    .of(propertySchema([]))
    .required("Antagonist ist erforderlich"),
});

export interface MuscleGroup extends yup.InferType<typeof muscleGroupSchema> {}

export const exerciseSchema = (languages: Localization[]) =>
  yup.object().shape({
    _id: yup.string().notRequired(),
    title: languageSchema(languages).required("Überschrift ist erforderlich"),
    coverImageUrl: yup.string().required("Bild ist erforderlich"),
    videoUrl: yup.string().notRequired(),
    difficultyLevel: yup.number().integer().positive(),
    infos: yup.array().of(infoListSchema(languages)).nullable().default([]),
    properties: yup.array().of(propertySchema([])).nullable(),
    conditions: yup.mixed(),
    user: userSchema.nullable().default(null),
    studio: studioSchema.nullable().default(null),
    contentLibrary: contentLibrarySchema(languages)
      .nullable()
      .notRequired()
      .default(null),
    muscleGroups: muscleGroupSchema.required(),
    type: yup.string(),
  });

export interface Exercise
  extends yup.InferType<ReturnType<typeof exerciseSchema>> {
  system?: System;
}

export interface Option {
  name: string;
  data: any;
}

export const exerciseToJson = (exercise: Exercise): any => {
  let dataToSave = exercise;
  let muscleGroups: any;

  if (exercise.muscleGroups?.agonist != null) {
    muscleGroups = {
      ...muscleGroups,
      agonist: exercise.muscleGroups?.agonist.map((item) => item?._id),
    };
  }

  if (exercise.muscleGroups?.synergist != null) {
    muscleGroups = {
      ...muscleGroups,
      synergist: exercise.muscleGroups?.synergist?.map((item) => item?._id),
    };
  }

  if (exercise.muscleGroups?.antagonist != null) {
    muscleGroups = {
      ...muscleGroups,
      antagonist: exercise.muscleGroups?.antagonist?.map((item) => item?._id),
    };
  }

  if (muscleGroups != null) {
    dataToSave = {
      ...exercise,
      muscleGroups,
    };
  }

  return dataToSave;
};
