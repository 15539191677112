import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpPermissionGroupService } from "services/httpClients/http.permission.group.client";
import { PermissionGroup } from "schemas/permission.group.schema";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";

class PermissionGroupStore {
  private stores: RootStore;

  // Properties
  private _permissionGroupsDataList: PaginationDataList<PermissionGroup> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentPermissionGroup: DataItem<PermissionGroup> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setPermissionGroups = (permissionGroups: PermissionGroup[]): void => {
    this._permissionGroupsDataList.data = permissionGroups;
  };

  setCurrentPermissionGroup = (
    permissionGroup: PermissionGroup | undefined
  ): void => {
    this._currentPermissionGroup.data = permissionGroup;
  };

  //! Getters
  get permissionGroups(): PaginationDataList<PermissionGroup> | undefined {
    if (this._permissionGroupsDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._permissionGroupsDataList));
  }

  get currentPermissionGroup(): DataItem<PermissionGroup> | undefined {
    if (this._currentPermissionGroup == null) {
      return;
    }
    return this._currentPermissionGroup;
  }

  //! Methods
  fetchAndSetPermissionGroups = async (args: {
    filter?: any;
  }): Promise<void> => {
    const { filter } = args;

    try {
      if (this._permissionGroupsDataList.isLoading) {
        return;
      }

      this._permissionGroupsDataList.isLoading = true;

      const permissionGroups =
        await HttpPermissionGroupService.getInstance().find({
          query: {
            ...getSkipAndLimitFromPage({
              pageIndex: this._permissionGroupsDataList.pageIndex,
              itemsInPage: this._permissionGroupsDataList.itemsInPage,
            }),
            ...filter,
          },
        });

      if (permissionGroups == null) {
        this._permissionGroupsDataList.isLoading = false;
        return;
      }

      this.setPermissionGroups(permissionGroups);

      this._permissionGroupsDataList.isLoading = false;
    } catch (err) {
      this._permissionGroupsDataList.isLoading = false;

      Logging.error({
        className: "PermissionGroupStore",
        methodName: "fetchAndSetPermissionGroup",
        message: "Could not get permission groups",
        exception: err,
        showAlert: true,
      });
    }
  };

  removePermissionGroup = async (args: {
    permissionGroupId: string;
    filter?: any;
  }): Promise<void> => {
    const { permissionGroupId, filter } = args;

    try {
      await HttpPermissionGroupService.getInstance().archiveOne({
        id: permissionGroupId,
      });

      const permissionGroups =
        await HttpPermissionGroupService.getInstance().find({
          query: {
            ...getSkipAndLimitFromPage({
              pageIndex: this._permissionGroupsDataList.pageIndex,
              itemsInPage: this._permissionGroupsDataList.itemsInPage,
            }),
            ...filter,
          },
        });

      if (permissionGroups == null) {
        return;
      }

      this.setPermissionGroups(permissionGroups);
    } catch (err) {
      Logging.error({
        className: "PermissionGroupStore",
        methodName: "removePermissionGroup",
        message: "Could not remove permission group",
        exception: err,
        showAlert: true,
      });
    }
  };

  updatePermissionGroup = async (args: {
    permissionGroupId: string;
    data: any;
    filter?: any;
  }): Promise<void> => {
    const { permissionGroupId, data, filter } = args;

    try {
      await HttpPermissionGroupService.getInstance().updateOne({
        id: permissionGroupId,
        data,
        query: filter,
      });

      const permissionGroups =
        await HttpPermissionGroupService.getInstance().find({
          query: {
            ...getSkipAndLimitFromPage({
              pageIndex: this._permissionGroupsDataList.pageIndex,
              itemsInPage: this._permissionGroupsDataList.itemsInPage,
            }),
            ...filter,
          },
        });

      if (permissionGroups == null) {
        return;
      }

      this.setPermissionGroups(permissionGroups);
    } catch (err) {
      Logging.error({
        className: "PermissionGroupStore",
        methodName: "updatePermissionGroup",
        message: "Could not update permission group",
        exception: err,
        showAlert: true,
      });
    }
  };

  createPermissionGroup = async (args: {
    data: any;
    filter?: any;
  }): Promise<void> => {
    const { data, filter } = args;

    try {
      await HttpPermissionGroupService.getInstance().create({
        data,
        query: filter,
      });

      const permissionGroups =
        await HttpPermissionGroupService.getInstance().find({
          query: {
            ...getSkipAndLimitFromPage({
              pageIndex: this._permissionGroupsDataList.pageIndex,
              itemsInPage: this._permissionGroupsDataList.itemsInPage,
            }),
            ...filter,
          },
        });

      if (permissionGroups == null) {
        return;
      }

      this.setPermissionGroups(permissionGroups);
    } catch (err) {
      Logging.error({
        className: "PermissionGroupStore",
        methodName: "createPermissionGroup",
        message: "Could not create permission group",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default PermissionGroupStore;
