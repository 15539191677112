import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import marketingIllustrations from "assets/illustrations/invitation.png";
import FeedConfigList from "./components/feed.config.list.component";
import { inject, observer } from "mobx-react";
import FeedStore from "stores/feed.store";
import { FeedConfig } from "schemas/feed.schemas/feed.config.schema";
import StudioStore from "stores/studio.store";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { SearchTopic } from "stores/global.search.store";
import { ModalStore } from "stores/modal.store";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

interface FeedConfigOverviewPageProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const FeedConfigOverviewPage = ({
  feedStore,
  studioStore,
  modalStore,
}: FeedConfigOverviewPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage.config.overview");
  const navigate = navigationHelper();

  const feedConfigs = feedStore?.feedConfigs;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    await feedStore?.fetchAndSetFeedConfigs({});
  };

  const handleClickFeedConfig = (
    feedConfig: FeedConfig | undefined,
    isNew?: boolean
  ): void => {
    if (studioStore?.studio?._id == null) return;

    if (feedConfig?._id != null && !isNew) {
      navigate(`feed/${feedConfig?._id}/content`);
    } else {
      navigate(`feed/new/config`);
    }
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            image={marketingIllustrations}
            imagePosition="bottom center"
            title={translate("introTitle")}
            collapsedTitle={translate("collapsedIntroTitle")}
            description={translate("introDescription")}
            id="feed-config-overview-page"
            renderButtons={() => (
              <FilledButton
                onClick={() => {
                  handleClickFeedConfig(undefined, true);
                }}
                label={translate("addButton")}
              />
            )}
          />

          <Row justifyContent="flex-end">
            <FilledButton
              className="mb-15"
              label="Search"
              icon={faSearch}
              onClick={() => {
                modalStore?.openGlobalSearchModal(SearchTopic.FEED);
              }}
            />
          </Row>

          <FeedConfigList
            feedConfigs={feedConfigs?.data ?? []}
            isLoading={feedConfigs?.isLoading}
            onClick={(feedConfig) => handleClickFeedConfig(feedConfig, false)}
          />
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "modalStore",
  "feedStore",
  "studioStore"
)(observer(FeedConfigOverviewPage));
