import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { inject, observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { toast } from "react-toastify";

import { ModalStore } from "../../../stores/modal.store";
import OrganizationStore from "../../../stores/organization.store";
import { organizationSchema } from "schemas/organization.schemas/organization.schema";

import ModalWrapper from "../modal.wrapper.component/modal.wrapper.component";
import ModalContent from "../modal.content.component/modal.content.component";
import ModalHeader from "../modal.header.component/modal.header.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";

interface AddOrganizationModalProps {
  modalStore?: ModalStore;
  organizationStore?: OrganizationStore;
}

const AddOrganizationModal = ({
  modalStore,
  organizationStore,
}: AddOrganizationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "addOrganizationModal");

  const [isLoading, setIsLoading] = useState(false);

  const organizationFormMethods = useForm({
    resolver: yupResolver(organizationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting },
  } = organizationFormMethods;

  const onSubmit = async (form: {
    name: string;
    email: string;
  }): Promise<void> => {
    setIsLoading(true);

    const organization = await organizationStore?.createOrganization({
      data: form,
    });

    if (organization) {
      modalStore?.closeModal();
    }

    setIsLoading(false);
  };

  const _buildForm = (): JSX.Element => {
    return (
      <FormProvider {...organizationFormMethods}>
        <ModalWrapper>
          <ModalHeader title={translate("createNewOrganization")} />

          <form
            id="add-organization-modal-form"
            onSubmit={handleSubmit(onSubmit, () => {
              toast.error(translate("onSubmitError"));
            })}
          >
            <ModalContent gap={20}>
              <FormWrapper gap={20}>
                <OutlinedTextInput
                  required={true}
                  label={translate("name")}
                  inputRef={register("name")}
                />
                <OutlinedTextInput
                  required={true}
                  label={translate("email")}
                  inputRef={register("email")}
                />
              </FormWrapper>
              <Row justifyContent="flex-end">
                <FilledButton
                  type="submit"
                  color="primary"
                  isLoading={isLoading}
                  label={translate("create")}
                  disabled={!isDirty || isSubmitting}
                />
              </Row>
            </ModalContent>
          </form>
        </ModalWrapper>
      </FormProvider>
    );
  };

  return _buildForm();
};

export default inject(
  "modalStore",
  "organizationStore"
)(observer(AddOrganizationModal));
