import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpRoleTypeService } from "services/httpClients/http.role.type.client";
import { RoleType } from "schemas/role.type.schema";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";

class RoleTypeStore {
  private stores: RootStore;

  // Properties
  private _roleTypesDataList: PaginationDataList<RoleType> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentRoleType: DataItem<RoleType> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setRoleTypes = (roleTypes: RoleType[]): void => {
    this._roleTypesDataList.data = roleTypes;
  };

  setCurrentRoleType = (roleType: RoleType | undefined): void => {
    this._currentRoleType.data = roleType;
  };

  //! Getters
  get roleTypes(): PaginationDataList<RoleType> | undefined {
    if (this._roleTypesDataList == null) {
      return;
    }
    return this._roleTypesDataList;
  }

  get currentRoleType(): DataItem<RoleType> | undefined {
    if (this._currentRoleType == null) {
      return;
    }
    return this._currentRoleType;
  }

  //! Methods
  fetchAndSetRoleTypes = async (args: { filter?: any }): Promise<void> => {
    const { filter } = args;

    try {
      if (this._roleTypesDataList.isLoading) {
        return;
      }

      this._roleTypesDataList.isLoading = true;

      const roleTypes = await HttpRoleTypeService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._roleTypesDataList.pageIndex,
            itemsInPage: this._roleTypesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roleTypes == null) {
        this._roleTypesDataList.isLoading = false;
        return;
      }

      this.setRoleTypes(roleTypes);

      this._roleTypesDataList.isLoading = false;
    } catch (err) {
      this._roleTypesDataList.isLoading = false;

      Logging.error({
        className: "RoleTypeStore",
        methodName: "fetchAndSetRoleType",
        message: "Could not get roleTypes",
        exception: err,
        showAlert: true,
      });
    }
  };

  removeRoleType = async (args: {
    roleTypeId: string;
    filter?: any;
  }): Promise<void> => {
    const { roleTypeId, filter } = args;

    try {
      await HttpRoleTypeService.getInstance().archiveOne({ id: roleTypeId });

      const roleTypes = await HttpRoleTypeService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._roleTypesDataList.pageIndex,
            itemsInPage: this._roleTypesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roleTypes == null) {
        return;
      }

      this.setRoleTypes(roleTypes);
    } catch (err) {
      Logging.error({
        className: "RoleTypeStore",
        methodName: "removeRoleType",
        message: "Could not remove roleType",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateRoleType = async (args: {
    roleTypeId: string;
    data: any;
    filter?: any;
  }): Promise<void> => {
    const { roleTypeId, data, filter } = args;

    try {
      await HttpRoleTypeService.getInstance().updateOne({
        id: roleTypeId,
        data,
        query: filter,
      });

      const roleTypes = await HttpRoleTypeService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._roleTypesDataList.pageIndex,
            itemsInPage: this._roleTypesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roleTypes == null) {
        return;
      }

      this.setRoleTypes(roleTypes);
    } catch (err) {
      Logging.error({
        className: "RoleTypeStore",
        methodName: "updateRoleType",
        message: "Could not update roleType",
        exception: err,
        showAlert: true,
      });
    }
  };

  createRoleType = async (args: { data: any; filter?: any }): Promise<void> => {
    const { data, filter } = args;

    try {
      await HttpRoleTypeService.getInstance().create({
        data,
        query: filter,
      });

      const roleTypes = await HttpRoleTypeService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._roleTypesDataList.pageIndex,
            itemsInPage: this._roleTypesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roleTypes == null) {
        return;
      }

      this.setRoleTypes(roleTypes);
    } catch (err) {
      Logging.error({
        className: "RoleTypeStore",
        methodName: "createRoleType",
        message: "Could not create roleType",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default RoleTypeStore;
