import React from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import { ModalStore } from "stores/modal.store";
import RoleStore from "stores/role.store";
import { Role } from "schemas/role.schema";
import { ResourceType } from "globals/enums/global.enum";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";

import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface RoleListProps {
  roles: Role[];
  handleClickRole: (role: Role, isNew: boolean) => void;
  isLoading?: boolean;
  roleStore?: RoleStore;
  modalStore?: ModalStore;
  organization: string | undefined;
}

const RoleList = ({
  roles,
  handleClickRole,
  isLoading = false,
  roleStore,
  modalStore,
  organization,
}: RoleListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.userRoles");

  const removeRole = (role: Role): void => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        if (role?._id == null) return;
        roleStore?.removeRole({
          roleId: role._id,
          filter: {
            resourceType: ResourceType.ORGANIZATION,
            resource: organization,
          },
        });
      },
      confirmLabel: translate("buttons.deleteRoleButton.confirmLabel"),
      title: translate("buttons.deleteRoleButton.title"),
      description: translate("buttons.deleteRoleButton.description"),
    });
  };

  return (
    <ListDataTable
      data={roles || []}
      noDataMessage={translate("list.noDataMessage")}
      isLoading={isLoading}
      listDropdownMenu={{
        items: [
          {
            label: translate("list.listDropdownMenu.edit"),
            icon: faEdit,
            onClick: (role: Role) => handleClickRole(role, false),
          },
          {
            label: translate("list.listDropdownMenu.delete"),
            icon: faTrash,
            onClick: (role: Role) => removeRole(role),
          },
        ],
        placement: "left",
      }}
      columns={[
        {
          child: <TitleText tableHeader>{translate("list.role")}</TitleText>,
          flex: 1,
        },
        {
          child: (
            <TitleText tableHeader>{translate("list.roleType")}</TitleText>
          ),
          flex: 1,
        },
        {
          child: (
            <TitleText tableHeader>{translate("list.createdBy")}</TitleText>
          ),
          flex: 1,
        },
        {
          child: (
            <TitleText tableHeader>{translate("list.createdAt")}</TitleText>
          ),
          flex: 1,
        },
      ]}
      dataTableItemBuilder={(dataItem: Role) => {
        return {
          key: dataItem._id,
          children: [
            {
              child: <RunningText>{dataItem?.title}</RunningText>,
            },
            {
              child: <RunningText>{dataItem?.roleType?.name}</RunningText>,
            },
            {
              child: (
                <RunningText>{`${dataItem?.creator?.firstName} ${dataItem?.creator?.lastName}`}</RunningText>
              ),
            },
            {
              child: (
                <RunningText>
                  {formatDate(dataItem?.system?.createdAt)}
                </RunningText>
              ),
            },
          ],
        } as any;
      }}
    />
  );
};

export default inject("roleStore", "modalStore")(observer(RoleList));
