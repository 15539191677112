import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { inject, observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { toast } from "react-toastify";
import { ModalStore } from "../../../stores/modal.store";
import RoleStore from "stores/role.store";
import { RoleType } from "schemas/role.type.schema";
import { ResourceType } from "globals/enums/global.enum";

import ModalWrapper from "../modal.wrapper.component/modal.wrapper.component";
import ModalContent from "../modal.content.component/modal.content.component";
import ModalHeader from "../modal.header.component/modal.header.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import NewSelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/new.dropdown.component";

interface Option {
  label: string;
  value: string;
}

interface AddRoleModalProps {
  roleStore?: RoleStore;
  modalStore?: ModalStore;
}

const roleFormSchema = yup.object().shape({
  title: yup.string().required(),
  roleType: yup.string().required(),
});

const AddRoleModal = ({
  roleStore,
  modalStore,
}: AddRoleModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.modals");

  const [isLoading, setIsLoading] = useState(false);

  const { role, roleTypes, organization } = modalStore?.customData;

  const roleFormMethods = useForm({
    resolver: yupResolver(roleFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    values: {
      title: role?.title ?? "",
      roleType: role?.roleType?._id ?? "",
    },
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
    clearErrors,
  } = roleFormMethods;

  const onSubmit = async (form: {
    title: string;
    roleType: string;
  }): Promise<void> => {
    setIsLoading(true);

    const data: any = {
      title: form.title,
      roleType: form.roleType,
    };

    if (organization) {
      data.resourceType = ResourceType.ORGANIZATION;
      data.resource = organization;
    }

    if (role?._id) {
      await roleStore?.updateRole({
        roleId: role._id,
        data,
        filter: {
          resourceType: ResourceType.ORGANIZATION,
          resource: organization,
        },
      });
    } else {
      await roleStore?.createRole({
        data,
        filter: {
          resourceType: ResourceType.ORGANIZATION,
          resource: organization,
        },
      });
    }

    modalStore?.closeModal();

    setIsLoading(false);
  };

  const getRoleTypeOptions = (): Option[] | [] => {
    const options = roleTypes.map((item: RoleType) => {
      return { label: item.name, value: item._id };
    });
    return options;
  };

  const handleChangeRoleTypeOption = (item: Option): void => {
    setValueAndMarkValid("roleType", item?.value);
    clearErrors("roleType");
  };

  const setValueAndMarkValid = (name: any, value: any): void => {
    setValue(name, value, { shouldValidate: true });
  };

  return (
    <FormProvider {...roleFormMethods}>
      <ModalWrapper>
        <ModalHeader
          title={
            role?._id
              ? translate("titles.updateUserRole")
              : translate("titles.newUserRole")
          }
        />

        <form
          id="add-role-modal-form"
          onSubmit={handleSubmit(onSubmit, () => {
            toast.error(translate("errors.onSubmitError"));
          })}
        >
          <ModalContent gap={20}>
            <FormWrapper gap={20}>
              <OutlinedTextInput
                required={true}
                label={translate("fields.roleName")}
                inputRef={register("title")}
                inputRefValue={getValues("title")}
                validationMessage={errors.title?.message?.toString()}
              />

              <NewSelectDropDown
                required={true}
                label={translate("fields.typeName")}
                inputRef={register("roleType")}
                options={getRoleTypeOptions()}
                onChange={handleChangeRoleTypeOption}
                selectedItem={getRoleTypeOptions().find(
                  (item: Option) => item.value === role?.roleType?._id
                )}
              />
            </FormWrapper>

            <Row justifyContent="flex-end">
              <FilledButton
                type="submit"
                color="primary"
                isLoading={isLoading}
                label={
                  role?._id
                    ? translate("buttons.update")
                    : translate("buttons.create")
                }
                disabled={!isValid || isSubmitting}
              />
            </Row>
          </ModalContent>
        </form>
      </ModalWrapper>
    </FormProvider>
  );
};

export default inject("roleStore", "modalStore")(observer(AddRoleModal));
