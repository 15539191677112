import * as yup from "yup";
import { systemSchema } from "schemas/system.schema";

export const organizationSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  system: systemSchema.notRequired().default(undefined),
});

export interface Organization
  extends yup.InferType<typeof organizationSchema> {}
