import React, { useEffect } from "react";
import "./organization.permission.group.page.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { useParams } from "react-router-dom";
import PermissionGroupStore from "stores/permission.group.store";
import { PermissionGroup } from "schemas/permission.group.schema";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import Column from "components/general.compoenents/column.component/column.component";
import Headline from "components/text.components/headline.component/headline.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import ToggleSwitch from "components/input.components/toggle.switch.component/toggle.switch.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface OrganizationPermissionGroupPageProps {
  permissionGroupStore?: PermissionGroupStore;
}

const OrganizationPermissionGroupPage = ({
  permissionGroupStore,
}: OrganizationPermissionGroupPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.permissionGroups");

  const { organizationID } = useParams();
  const permissionGroups = permissionGroupStore?.permissionGroups?.data;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    permissionGroupStore?.fetchAndSetPermissionGroups({
      filter: {
        organization: organizationID,
      },
    });
  };

  const handlePermissionGroupToggle = (
    isChecked: boolean,
    group: PermissionGroup
  ): any => {
    if (group?.permissionItems?.length) {
      const updatedPermissionItems = group?.permissionItems.map((item: any) => {
        item.isActive = isChecked;
        return item;
      });

      permissionGroupStore?.updatePermissionGroup({
        permissionGroupId: group._id,
        data: {
          organization: group.organization,
          permissionItems: updatedPermissionItems,
        },
        filter: {
          organization: organizationID,
        },
      });
    }
  };

  const handlePermissionItemToggle = (
    isChecked: boolean,
    group: PermissionGroup,
    permissionItem: any
  ): any => {
    if (group?.permissionItems?.length) {
      const updatedPermissionItems = group?.permissionItems.map((item: any) => {
        if (item.permission === permissionItem.permission) {
          item.isActive = isChecked;
        }

        return item;
      });

      permissionGroupStore?.updatePermissionGroup({
        permissionGroupId: group._id,
        data: {
          organization: group.organization,
          permissionItems: updatedPermissionItems,
        },
        filter: {
          organization: organizationID,
        },
      });
    }
  };

  const checkActiveItems = (group: PermissionGroup): boolean => {
    if (group?.permissionItems?.length) {
      return !!group.permissionItems.find((item) => item.isActive);
    }

    return false;
  };

  const _buildPermissionGroupsList = (): JSX.Element => {
    if (!permissionGroups?.length) {
      return <></>;
    }

    return (
      <Column className="group-list-content" gap={20}>
        {permissionGroups.map((group: PermissionGroup) => {
          return (
            <Column key={group._id} gap={10}>
              <Row
                alignItems="center"
                justifyContent="space-between"
                className="permission-group-title"
              >
                <SmallText>{group.name}</SmallText>
                <ToggleSwitch
                  value={checkActiveItems(group)}
                  onChange={(isChecked: boolean) =>
                    handlePermissionGroupToggle(isChecked, group)
                  }
                />
              </Row>

              {group?.permissionItems?.length &&
                group.permissionItems.map(
                  (
                    item: { permission: string; isActive: boolean },
                    index: number
                  ) => {
                    return (
                      <Row
                        key={index}
                        alignItems="center"
                        justifyContent="space-between"
                        className="permission-group-item"
                      >
                        <SmallText>{item.permission}</SmallText>
                        <ToggleSwitch
                          value={item.isActive}
                          onChange={(isChecked: boolean) =>
                            handlePermissionItemToggle(isChecked, group, item)
                          }
                        />
                      </Row>
                    );
                  }
                )}
            </Column>
          );
        })}
      </Column>
    );
  };

  return (
    <PageContainer className="permission-group-page-container">
      <WidthLimiterWrapper>
        <Row className="permission-group-page-header">
          <Headline className="mt-20 mb-20">Role Permissions</Headline>
        </Row>
        <Column className="permission-group-page-list">
          <Row justifyContent="flex-end" className="group-list-header mb-30">
            <FilledButton
              label={translate("buttons.createNewGroup")}
              icon={faPlus}
              color="light"
            />
          </Row>
          {_buildPermissionGroupsList()}
        </Column>
      </WidthLimiterWrapper>
    </PageContainer>
  );
};

export default inject("permissionGroupStore")(
  observer(OrganizationPermissionGroupPage)
);
