import SidebarNavigation from "components/navigation.components/sidebar.component/sidebar.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import StudioStore from "stores/studio.store";
import OrganizationStore from "stores/organization.store";
import OrganizationSidebar from "components/navigation.components/organization.sidebar.component/organization.sidebar.component";
import { SidebarProvider } from "components/navigation.components/sidebar.provider.component/sidebar.provider.component";

interface DashboardPageProps {
  studioStore?: StudioStore;
  organizationStore?: OrganizationStore;
}

const DashboardPage = ({
  studioStore,
  organizationStore,
}: DashboardPageProps): JSX.Element => {
  const { organizationID, studioID } = useParams();

  const location = useLocation();
  const route = location.pathname.split("/")[1];

  useEffect(() => {
    if (route === "studios") {
      studioStore?.getUserStudios();
    }

    if (route === "organizations" && organizationID) {
      organizationStore?.getOrganization({ organizationId: organizationID });
    }
  }, [studioID, organizationID]);

  if (route === "studios") {
    return (
      <MainLayout sideBars={[SidebarNavigation({})]}>
        <Outlet />
      </MainLayout>
    );
  } else if (route === "organizations") {
    return (
      <MainLayout
        sideBars={[
          <SidebarProvider key="organization-sidebar">
            <OrganizationSidebar />
          </SidebarProvider>,
        ]}
      >
        <Outlet />
      </MainLayout>
    );
  } else {
    return <></>;
  }
};

export default inject(
  "studioStore",
  "organizationStore"
)(observer(DashboardPage));
