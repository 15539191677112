import React from "react";
import { ReactComponent as GymoIcon } from "assets/icons/gymo_logo.svg";
import { ReactComponent as GymoTitle } from "assets/icons/gymo.svg";
import "./gymo.logo.component.scss";
import classNames from "classnames";
import Row from "../row.component/row.component";

interface GymoLogoProps {
  className?: string;
  size?: number;
  lettering?: boolean;
}

const GymoLogo = ({
  className,
  size = 50,
  lettering,
}: GymoLogoProps): JSX.Element => {
  const gymoLogoClass = classNames(
    {
      "gymo-icon": true,
      "gymo-icon--lettering": lettering,
    },
    className
  );

  const gymoTitleClass = classNames(
    {
      "gymo-icon": true,
      "gymo-title--lettering": lettering,
    },
    className
  );

  return (
    <Row>
      <GymoIcon className={gymoLogoClass} style={{ height: size }} />
      {lettering && (
        <GymoTitle className={gymoTitleClass} style={{ height: size }} />
      )}
    </Row>
  );
};

export default GymoLogo;
