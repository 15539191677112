import { PermissionGroup } from "schemas/permission.group.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpPermissionGroupService extends GenericHttpClient<PermissionGroup> {
  static _instance: HttpPermissionGroupService;
  static getInstance(): HttpPermissionGroupService {
    if (this._instance == null) {
      this._instance = new HttpPermissionGroupService("/permission-groups");
    }
    return this._instance;
  }
}
