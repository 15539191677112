import React from "react";
import "./modal.content.component.scss";

interface ModalContentProps {
  children: React.ReactNode;
  gap?: number;
}

const ModalContent = ({ children, gap }: ModalContentProps): JSX.Element => {
  return (
    <div className="default-modal-content" style={{ gap }}>
      {children}
    </div>
  );
};

export default ModalContent;
