import React, { useEffect } from "react";
import "./mesh.background.component.scss";
import { Gradient } from "whatamesh";

interface MeshBackgroundProps {
  selector: string;
}

const MeshBackground = ({ selector }: MeshBackgroundProps): JSX.Element => {
  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient(`#${selector}`);
  }, []);

  return (
    <canvas
      id={selector}
      className="gradient-canvas"
      data-js-darken-top={true}
    ></canvas>
  );
};

export default MeshBackground;
