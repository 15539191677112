import { Organization } from "schemas/organization.schemas/organization.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpOrganizationService extends GenericHttpClient<Organization> {
  static _instance: HttpOrganizationService;
  static getInstance(): HttpOrganizationService {
    if (this._instance == null) {
      this._instance = new HttpOrganizationService("/organizations");
    }
    return this._instance;
  }
}
