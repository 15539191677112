import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface SidebarContextType {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const defaultContextValue: SidebarContextType = {
  isCollapsed: false,
  toggleCollapse: () => {},
};

const SidebarContext = createContext<SidebarContextType>(defaultContextValue);

export const useSidebar = (): SidebarContextType => useContext(SidebarContext);

export const SidebarProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = (): void => {
      setIsCollapsed(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleCollapse = (): void => setIsCollapsed(!isCollapsed);

  return (
    <SidebarContext.Provider value={{ isCollapsed, toggleCollapse }}>
      {children}
    </SidebarContext.Provider>
  );
};
