import React from "react";
import "./role.page.component.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import RoleStore from "stores/role.store";
import { ModalStore, ModalType, ModalSize } from "stores/modal.store";
import { Role } from "schemas/role.schema";
import { RoleType } from "schemas/role.type.schema";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import RoleList from "components/table.components/role.list.component/role.list.component";

interface RolePageProps {
  roles: Role[] | [];
  roleTypes: RoleType[] | [];
  organization: string | undefined;
  roleStore?: RoleStore;
  modalStore?: ModalStore;
}

const RolePage = ({
  roles,
  roleTypes,
  organization,
  roleStore,
  modalStore,
}: RolePageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.userRoles");

  const handleClickRole = (
    role: Role | undefined,
    isNew?: boolean | undefined
  ): void => {
    if (role?._id != null && !isNew) {
      modalStore?.openModal(ModalType.ADD_ROLE_MODAL, ModalSize.SMALL, {
        role,
        roleTypes,
        organization,
        overflow: "visible",
      });
    } else {
      modalStore?.openModal(ModalType.ADD_ROLE_MODAL, ModalSize.SMALL, {
        roleTypes,
        organization,
        overflow: "visible",
      });
    }
  };

  return (
    <Column className="role-list" alignItems="center">
      <Row justifyContent="flex-end" className="role-list-header mb-30">
        <FilledButton
          label={translate("buttons.createNewRole")}
          icon={faPlus}
          color="light"
          onClick={() => handleClickRole(undefined, true)}
        />
      </Row>
      <RoleList
        isLoading={roleStore?.roles?.isLoading}
        roles={roles}
        handleClickRole={handleClickRole}
        organization={organization}
      />
    </Column>
  );
};

export default inject("roleStore", "modalStore")(observer(RolePage));
