import React, { useEffect, useState } from "react";
import "./toggle.switch.component.scss";
import classNames from "classnames";

import Row from "components/general.compoenents/row.component/row.component";
import { RunningText } from "../../text.components/running.text.component/running.text.component";

interface ToggleSwitchProps {
  className?: string;
  value?: boolean;
  onChange?: (isChecked: boolean) => void;
  label?: string;
  disabled?: boolean;
}

const ToggleSwitch = ({
  className,
  value,
  label,
  onChange,
  disabled,
}: ToggleSwitchProps): JSX.Element => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (value === true || value === false) {
      setChecked(value);
    }
  }, [value]);

  const toggleSwitchClassName = classNames(
    {
      "toggle-switch-wrapper": true,
      "toggle-switch-wrapper--disabled": disabled,
    },
    className
  );

  const handleToggle = (): void => {
    if (disabled) return;

    setChecked((value) => !value);
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <Row
      className={toggleSwitchClassName}
      alignItems="center"
      onClick={() => handleToggle()}
    >
      <input
        type="checkbox"
        id="cbx"
        style={{ display: "none" }}
        checked={checked}
        readOnly
      />
      <label
        htmlFor="cbx"
        className="toggle"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleToggle();
        }}
      >
        <span></span>
      </label>
      <RunningText className="ml-10">{label}</RunningText>
    </Row>
  );
};

export default ToggleSwitch;
