/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import CustomerStore from "stores/customer.store";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Column from "components/general.compoenents/column.component/column.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { useFormContext } from "react-hook-form";
import NewSelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/new.dropdown.component";
import trash from "assets/icons/trash.svg";
import OutlinedDateInput, {
  Range,
} from "components/input.components/outlined.date.input.component/outlined.date.input.component";
import Grid from "components/general.compoenents/grid.component/grid.component";

interface CustomerInfoTabProps {
  customerStore?: CustomerStore;
  studioStore?: StudioStore;
}

const CustomerInfoTab = ({
  customerStore,
  studioStore,
}: CustomerInfoTabProps): JSX.Element => {
  const currentCustomer = customerStore?.currentCustomer?.data;
  const currentLanguage = studioStore?.currentLanguage;

  const {
    register,
    getValues,
    formState: { errors, isDirty },
  } = useFormContext();

  if (!currentCustomer) return <></>;

  // TODO: Replace with real data

  const sexOptions = [
    {
      label: "Weiblich",
      value: "FEMALE",
      image:
        "https://storage.gymo.io/training-plans/b834be1130971b6fece0dbc57b78a75aaff0c9687019fd3fb79aa628521f4421.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Männlich",
      value: "MALE",
      image:
        "https://storage.gymo.io/training-plans/3ead47e3f8b4c65cdc1c8e29d58b3d607db2423c8d85ea0d554697350289902d.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Divers",
      value: "OTHER",
      image:
        "https://storage.gymo.io/training-plans/d5519ec224a15f8cb06896dc077d8061637df91835f710ba957962e14080f5b3.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test1",
      value: "TEST1",
      image:
        "https://storage.gymo.io/training-plan/7152407e0c900d266fb031144762c984c48d4194c96e45dd0569e5c2f10c1d72.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test2",
      value: "TEST2",
      image:
        "https://storage.gymo.io/training-plans/b834be1130971b6fece0dbc57b78a75aaff0c9687019fd3fb79aa628521f4421.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test3",
      value: "TEST3",
      image:
        "https://storage.gymo.io/training-plans/3ead47e3f8b4c65cdc1c8e29d58b3d607db2423c8d85ea0d554697350289902d.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test4",
      value: "TEST4",
      image:
        "https://storage.gymo.io/training-plans/d5519ec224a15f8cb06896dc077d8061637df91835f710ba957962e14080f5b3.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test5",
      value: "TEST5",
      image:
        "https://storage.gymo.io/training-plan/7152407e0c900d266fb031144762c984c48d4194c96e45dd0569e5c2f10c1d72.png",
      icon: trash,
      metaData: "test",
    },
  ];

  const handleChangeSexOption = (item: any): void => {
    console.log("handleChangeSexOption item:", item);
  };

  const handleDateChange = (dateData: {
    type: "single" | "range";
    value: string | Range;
  }): void => {
    console.log(dateData);
  };

  const _buildCustomerDataSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Kundendaten" className="mb-20">
        <FormWrapper className="mb-20">
          <Row gap={20} className="mobile-row-break">
            <SizedContainer size={ContainerSizes.CUSTOM} customSize={390}>
              <FileUpload
                minHeight={390}
                aspectRatio={undefined}
                folder="profile-images"
                fileUrl={currentCustomer?.profileImageUrl ?? ""}
                validationMessage=""
              />
            </SizedContainer>
            <TextEditor
              minHeight={undefined}
              content={"Kunde bemerkungen..."}
              inputContentChanged={(content: any) => {}}
              className="customer-info-editor"
            />
          </Row>
        </FormWrapper>

        <FormWrapper title="Kontaktdaten" gap={15}>
          <Row gap={20}>
            <OutlinedTextInput
              inputRef={register("firstName")}
              inputRefValue={getValues("firstName")}
              label="Vorname"
            />
            <OutlinedTextInput
              inputRef={register("lastName")}
              inputRefValue={getValues("lastName")}
              label="Nachname"
            />
          </Row>

          <Row gap={20}>
            <OutlinedTextInput label="Titel" />
            <NewSelectDropDown
              required={true}
              isSelectedOptionsFirst={true}
              label="Geschlecht"
              options={sexOptions}
              inputRef={register("sex")}
              selectedItem={undefined}
              multiple={true}
              onChange={handleChangeSexOption}
            />
          </Row>

          <Grid templateColumns="repeat(2, 1fr)" gap={20}>
            <OutlinedTextInput label="Organisation" />
            <OutlinedDateInput
              localization={currentLanguage?.value}
              value={"2024-10-29"}
              onChange={handleDateChange}
            />
          </Grid>

          <Row gap={20}>
            <OutlinedTextInput
              inputRef={register("email")}
              inputRefValue={getValues("email")}
              label="Email"
            />
            <OutlinedTextInput label="Telefonnummer" />
          </Row>
        </FormWrapper>

        <FormWrapper title="Adresse" className="mt-20" gap={15}>
          <Row gap={20}>
            <OutlinedTextInput label="Postleitzahl" />
            <OutlinedTextInput label="Stadt" />
          </Row>

          <Row gap={20}>
            <OutlinedTextInput label="Straße & Hausnummer" />
            <OutlinedTextInput label="Land" />
          </Row>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  return <>{_buildCustomerDataSection()}</>;
};

export default inject(
  "customerStore",
  "studioStore"
)(observer(CustomerInfoTab));
