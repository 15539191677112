import React, { useEffect } from "react";
import "./studio.overview.page.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { useNavigate } from "react-router-dom";

import { ModalStore, ModalSize, ModalType } from "stores/modal.store";
import UserStore from "stores/user.store";
import OrganizationStore from "stores/organization.store";
import StudioStore from "stores/studio.store";
import { Studio } from "schemas/studio.schemas/studio.schema";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faGear } from "@fortawesome/pro-regular-svg-icons";

import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import CardComponent from "components/general.compoenents/card.components/card.component/card.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import MeshBackground from "components/general.compoenents/mesh.background.component/mesh.background.component";
import Center from "components/general.compoenents/center.component/center.component";
import Column from "components/general.compoenents/column.component/column.component";
import Grid from "components/general.compoenents/grid.component/grid.component";
import Row from "components/general.compoenents/row.component/row.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";

interface StudioOverviewPageProps {
  organizationStore?: OrganizationStore;
  studioStore?: StudioStore;
  userStore?: UserStore;
  modalStore?: ModalStore;
}

const StudioOverviewPage = ({
  organizationStore,
  studioStore,
  userStore,
  modalStore,
}: StudioOverviewPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "studio");
  const navigate = useNavigate();

  const organizations = organizationStore?.organizations;

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    organizationStore?.fetchAndSetOrganizations({});
  }, [studioStore?.studio, studioStore?.studios]);

  const initializePage = async (): Promise<void> => {
    await userStore?.fetchAndSetClientRoles();
    await organizationStore?.fetchAndSetOrganizations({});
    await studioStore?.fetchAndSetStudios({});
  };

  const openAddOrganizationModal = (): void => {
    modalStore?.openModal(ModalType.ADD_ORGANIZATION_MODAL, ModalSize.SMALL, {
      overflow: "visible",
    });
  };

  const openAddStudioModal = (organizationID: string): void => {
    modalStore?.openModal(ModalType.ADD_STUDIO_MODAL, ModalSize.SMALL, {
      organizationID,
      overflow: "visible",
    });
  };

  const removeStudio = (studioId: string): void => {
    modalStore?.showConfirmAlert({
      onConfirm: () => {
        if (!studioId) return;
        studioStore?.removeStudio({ studioId });
      },
      confirmLabel: translate("deleteButton.confirmLabel"),
      title: translate("deleteButton.title"),
      description: translate("deleteButton.description"),
    });
  };

  const _buildOrganizationSettingItem = (args: {
    icon: any;
    onClick?: () => void;
  }): JSX.Element => {
    const { icon, onClick } = args;

    return (
      <Row
        className="orga-setting-item ml-10 cursor-pointer"
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
      >
        <FontAwesomeIcon className="orga-setting-item-icon" icon={icon} />
      </Row>
    );
  };

  const _buildOrganizationCards = (): JSX.Element[] => {
    const organizationElements: JSX.Element[] = [];

    organizations?.data?.forEach((organization: any) => {
      const activeStudios = organization?.studios?.filter(
        (studio: Studio) => !studio.system?.archived
      );

      organizationElements.push(
        <ComponentWrapper
          key={organization._id}
          className="organization-section mb-15"
        >
          <Row
            className="mb-10"
            justifyContent="space-between"
            alignItems="center"
          >
            <SmallHeadline className="organization-title">
              {organization.name}
            </SmallHeadline>
            <Row className="auto-width" alignItems="center">
              {_buildOrganizationSettingItem({
                icon: faPlus,
                onClick: () => openAddStudioModal(organization._id),
              })}
              {_buildOrganizationSettingItem({
                icon: faGear,
                onClick: () => {
                  navigate(`/organizations/${organization._id}`);
                },
              })}
            </Row>
          </Row>
          <Grid gap={10} templateColumns="repeat(3, 1fr)">
            {activeStudios?.map((studio: Studio) => (
              <CardComponent
                key={studio._id}
                title={studio.name}
                value="Miami, FL"
                imageUrl={studio?.logoUrl}
                onClick={() => {
                  studioStore?.setStudio(studio);
                  organizationStore?.setCurrentOrganization(organization);
                  navigate(`/studios/${studio._id}`);
                }}
                onDelete={() => removeStudio(studio._id)}
              />
            ))}
          </Grid>
        </ComponentWrapper>
      );
    });

    return organizationElements;
  };

  return (
    <Column className="studio-overview-screen">
      <MeshBackground selector="mesh-studio-overview" />
      <Center className="studio-overview">
        <Column className="studio-overview-content" alignItems="center">
          {_buildOrganizationCards()}
          <FilledButton
            icon={faPlus}
            label={translate("addOrganization")}
            onClick={openAddOrganizationModal}
            className="mt-15 add-organization-button"
          />
        </Column>
      </Center>
    </Column>
  );
};

export default inject(
  "organizationStore",
  "studioStore",
  "userStore",
  "modalStore"
)(observer(StudioOverviewPage));
