import React from "react";
import "./role.type.page.component.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import RoleTypeStore from "stores/role.type.store";
import { ModalStore, ModalType, ModalSize } from "stores/modal.store";
import { RoleType } from "schemas/role.type.schema";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import RoleTypeList from "components/table.components/role.type.list.component/role.type.list.component";

interface RoleTypePageProps {
  roleTypes: RoleType[] | [];
  organization: string | undefined;
  roleTypeStore?: RoleTypeStore;
  modalStore?: ModalStore;
}

const RoleTypePage = ({
  roleTypes,
  organization,
  roleTypeStore,
  modalStore,
}: RoleTypePageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.userRoles");

  const handleClickRoleType = (
    roleType: RoleType | undefined,
    isNew?: boolean | undefined
  ): void => {
    if (roleType?._id != null && !isNew) {
      modalStore?.openModal(ModalType.ADD_ROLE_TYPE_MODAL, ModalSize.SMALL, {
        roleType,
        organization,
      });
    } else {
      modalStore?.openModal(ModalType.ADD_ROLE_TYPE_MODAL, ModalSize.SMALL, {
        organization,
      });
    }
  };

  return (
    <Column className="role-type-list" alignItems="center">
      <Row justifyContent="flex-end" className="role-type-list-header mb-30">
        <FilledButton
          label={translate("buttons.createNewRoleType")}
          icon={faPlus}
          color="light"
          onClick={() => handleClickRoleType(undefined, true)}
        />
      </Row>
      <RoleTypeList
        isLoading={roleTypeStore?.roleTypes?.isLoading}
        roleTypes={roleTypes}
        handleClickRoleType={handleClickRoleType}
        organization={organization}
      />
    </Column>
  );
};

export default inject("roleTypeStore", "modalStore")(observer(RoleTypePage));
