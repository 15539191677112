import React from "react";
import "./image.placeholder.component.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ImagePlaceholderProps {
  label: string;
  icon?: any;
  className?: string;
}

const ImagePlaceholder = ({
  label,
  icon,
  className,
}: ImagePlaceholderProps): JSX.Element => {
  const firstLetter = label.charAt(0).toUpperCase();

  const imagePlaceholderClassName = classNames(
    {
      "image-placeholder": true,
    },
    className
  );

  return (
    <div className={imagePlaceholderClassName}>
      {icon ? <FontAwesomeIcon icon={icon} /> : <span>{firstLetter}</span>}
    </div>
  );
};

export default ImagePlaceholder;
