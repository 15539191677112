import React, { useEffect, useState } from "react";
import "./task.overview.page.scss";
import { inject, observer } from "mobx-react";
import { ModalStore, ModalType, ModalSize } from "stores/modal.store";
import TaskStore from "stores/task.store";
import StudioStore from "stores/studio.store";
import { Task } from "schemas/task.schemas/task.schema";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import { TaskStatusType, TaskListType } from "globals/enums/global.enum";
import { taskListTypeOptions } from "globals/data/globals.data";

import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import planIllustration from "assets/illustrations/feed-mockup.png";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import Row from "components/general.compoenents/row.component/row.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import TaskList from "components/table.components/task.list.component/task.list.component";
import TaskBoard from "components/board.components/task.board.component/task.board.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface TaskOverviewPageProps {
  taskStore?: TaskStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const TaskOverviewPage = ({
  taskStore,
  studioStore,
  modalStore,
}: TaskOverviewPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.overview");

  const [statusType, setStatusType] = useState(TaskStatusType.ALL);
  const [taskListType, setTaskListType] = useState(TaskListType.LIST);

  const tasks = taskStore?.tasks?.data ?? [];

  useEffect(() => {
    initializePage();
  }, [statusType]);

  const initializePage = async (): Promise<void> => {
    if (statusType === TaskStatusType.ALL) {
      taskStore?.fetchAndSetTasks({});
    } else {
      taskStore?.fetchAndSetTasks({ filter: { status: statusType } });
    }
  };

  const handleClickTask = (
    task: Task | undefined,
    isNew?: boolean | undefined
  ): void => {
    if (studioStore?.studio?._id == null) return;
    if (task?._id != null && !isNew) {
      taskStore?.setCurrentTask(task);
      modalStore?.openModal(ModalType.TASK_MANAGEMENT_MODAL, ModalSize.LARGE);
    } else {
      taskStore?.setCurrentTask(undefined);
      modalStore?.openModal(ModalType.TASK_MANAGEMENT_MODAL, ModalSize.LARGE, {
        isNewTask: true,
      });
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={
          taskListType === TaskListType.LIST
            ? [
                <Tab
                  label={translate("status.myTasks")}
                  key="tasks-tab"
                  active={statusType === TaskStatusType.ALL}
                  onClick={() => {
                    setStatusType(TaskStatusType.ALL);
                  }}
                />,
                <Tab
                  label={translate("status.backlog")}
                  key="backlog-tab"
                  active={statusType === TaskStatusType.BACKLOG}
                  onClick={() => {
                    setStatusType(TaskStatusType.BACKLOG);
                  }}
                />,
                <Tab
                  label={translate("status.toDo")}
                  key="todo-tab"
                  active={statusType === TaskStatusType.TO_DO}
                  onClick={() => {
                    setStatusType(TaskStatusType.TO_DO);
                  }}
                />,
                <Tab
                  label={translate("status.inProgress")}
                  key="progress-tab"
                  active={statusType === TaskStatusType.IN_PROGRESS}
                  onClick={() => {
                    setStatusType(TaskStatusType.IN_PROGRESS);
                  }}
                />,
                <Tab
                  label={translate("status.done")}
                  key="done-tab"
                  active={statusType === TaskStatusType.DONE}
                  onClick={() => {
                    setStatusType(TaskStatusType.DONE);
                  }}
                />,
                <Tab
                  label={translate("status.archive")}
                  key="archive-tab"
                  active={statusType === TaskStatusType.ARCHIVE}
                  onClick={() => {
                    setStatusType(TaskStatusType.ARCHIVE);
                  }}
                />,
              ]
            : [
                <Tab
                  className="task-status-tab-item"
                  label={translate("status.myTasks")}
                  key="tasks-tab"
                />,
              ]
        }
      ></LinkTabs>
    );
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            id="task-overview-page"
            image={planIllustration}
            imagePosition="bottom center"
            title={translate("introTitle")}
            collapsedTitle={translate("collapsedIntroTitle")}
            description={translate("introDescription")}
            renderButtons={() => (
              <FilledButton
                onClick={() => {
                  handleClickTask(undefined, true);
                }}
                label={translate("addButton")}
              />
            )}
          />
          <Row justifyContent="space-between" className="task-tabs-bar">
            <Row justifyContent="flex-start">{_buildTabs()}</Row>
            <Row justifyContent="flex-end">
              <SelectDropDown
                className="task-list-type-select"
                items={taskListTypeOptions ?? []}
                selectedItem={taskListTypeOptions?.find((item) => {
                  return item.value === taskListType;
                })}
                onChange={(item) => {
                  setStatusType(TaskStatusType.ALL);
                  setTaskListType(item?.value);
                }}
              />
            </Row>
          </Row>
          {taskListType === TaskListType.LIST ? (
            <TaskList
              isLoading={taskStore?.tasks?.isLoading}
              tasks={tasks}
              onClick={(task: Task) => handleClickTask(task, false)}
            />
          ) : (
            <TaskBoard
              isLoading={taskStore?.tasks?.isLoading}
              tasks={tasks}
              onClick={(card: any) => {
                const task = tasks.find((task) => task._id === card.id);
                handleClickTask(task, false);
              }}
            />
          )}
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "taskStore",
  "studioStore",
  "modalStore"
)(observer(TaskOverviewPage));
