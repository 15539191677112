import React, { useEffect, useState } from "react";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { ContainerSizes } from "globals/enums/global.enum";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import ProfileTabPage from "pages/account.page/components/profile.tab.page/profile.tab.page";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import CustomerStore from "stores/customer.store";
import CustomerBookingTab from "./components/customer.booking.tab/customer.booking.tab";
import CustomerInfoTab from "./components/customer.info.tab/customer.info.tab";
import CreateCustomerTrainingPlan from "./components/training.plans.overview.tab/components/create.customer.training.plan.component";
import TrainingPlansOverViewTab from "./components/training.plans.overview.tab/training.plans.overview.tab";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import { usePersistentId } from "globals/helpers/form.key.helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { trainingPlanFormSchema } from "components/table.components/training.plan.exercise.list.component/training.plan.exercise.list.component";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import BackButton from "components/general.compoenents/back.button.component/back.button.component";
import { DataAttributeProvider } from "components/data.attribute.components/attribute.form.context.component/attribute.form.context.component";
import DataAttributeStore from "stores/data.attribute.store";
import DataAttributeFormBuilder from "components/data.attribute.components/data.attribute.form.builder.component/data.attribute.form.builder.component";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { Customer } from "schemas/customer.schemas/customer.schema";
import Headline from "components/text.components/headline.component/headline.component";
import TrainingPlanStore from "stores/training.plan.store";
import { generatePlaceholderAvatarUrl } from "globals/helpers/global.helper";

interface CustomerDetailPageProps {
  customerStore?: CustomerStore;
  dataAttributeStore?: DataAttributeStore;
  trainingPlanStore?: TrainingPlanStore;
}

// TODO make file prettier !!
const CustomerDetailPage = ({
  customerStore,
  dataAttributeStore,
  trainingPlanStore,
}: CustomerDetailPageProps): JSX.Element => {
  const CUSTOMER_COLLECTION = "66337f44a2e2074e5eb6e087"; // TODO HARDCODED VALUE

  const { customerID } = useParams();
  const location = useLocation();

  const persistentId = usePersistentId(customerID);
  const [dynamicTabs, setDynamicTabs] = useState<DataAttribute[]>([]);

  const isPlanDetailActive = location?.pathname?.includes("training-plans/");
  const currentTrainingPlan = trainingPlanStore?.currentTrainingPlan?.data;

  const currentCustomer = customerStore?.currentCustomer;
  const fullName = `${currentCustomer?.data?.firstName} ${currentCustomer?.data?.lastName}`;

  const trainingPlanForm = useForm<TrainingPlan>({
    resolver: yupResolver(trainingPlanFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    initializePage();
  }, [customerID]);

  const initializePage = async (): Promise<void> => {
    if (customerID == null) {
      return;
    }

    await customerStore?.fetchAndSetCustomer({ customerID });

    await customerStore?.fetchAndSetCustomerTrainingPlans({
      customerID,
      refresh: true,
    });

    await dataAttributeStore?.fetchAndSetAttributesForCollection({
      collectionID: CUSTOMER_COLLECTION,
    });

    const attributes = await dataAttributeStore?.getCollectionAttributes(
      CUSTOMER_COLLECTION
    );

    const newTabAttributes = attributes?.data.filter(
      (attr) =>
        attr?.attributeSettings &&
        (attr.attributeSettings as any)?.showInNewTab === true
    );

    setDynamicTabs(newTabAttributes ?? []);
  };

  const onSubmit = async (customer: Customer): Promise<void> => {
    if (customer?._id) {
      await customerStore?.updateCustomer({
        id: customer._id!,
        customer,
      });
    } else {
      // TODO create new customer
    }
  };

  const getFixedToolbarTitle = (): string => {
    if (isPlanDetailActive) {
      return currentTrainingPlan?.title ?? "";
    }

    return fullName;
  };

  const getFixedToolbarImage = (): string => {
    if (isPlanDetailActive) {
      return (
        currentTrainingPlan?.coverImageUrl ??
        generatePlaceholderAvatarUrl(currentTrainingPlan?.title)
      );
    }

    return (
      currentCustomer?.data?.profileImageUrl ??
      generatePlaceholderAvatarUrl(fullName)
    );
  };

  const _buildTabs = (): JSX.Element => {
    const staticTabs: JSX.Element[] = [
      <Tab
        label="Kundendaten"
        key="2"
        path="info"
        rootPath={`${customerID}`}
      />,
      <Tab
        label="Betreuung"
        key="3"
        path="training-plans"
        rootPath={`${customerID}`}
      />,
      <Tab
        label="Buchungen"
        key="4"
        path="bookings"
        rootPath={`${customerID}`}
      />,
      <Tab
        label="Data Attributes"
        key="5"
        path="data-attribute"
        rootPath={`${customerID}`}
      />,
    ];

    // build dynamic tabs based on data attribute setting
    const dynamicAttributeTabs = dynamicTabs?.map((attr: DataAttribute) => (
      <Tab
        label={attr.label}
        key={attr.fieldID}
        path={`${attr.fieldID}`}
        rootPath={`${customerID}`}
      />
    ));

    return (
      <PageHeadline rootPath="customers">
        <BackButton
          className="mr-15"
          rootPath={isPlanDetailActive ? "training-plans" : "members"}
        />
        {isPlanDetailActive ? (
          <Headline>{currentTrainingPlan?.title}</Headline>
        ) : (
          <LinkTabs tabs={[...staticTabs, ...dynamicAttributeTabs]} />
        )}
      </PageHeadline>
    );
  };

  if (currentCustomer?.isLoading) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildTabs()]}>
      <PageContainer backgroundColor="color-surface" key={persistentId}>
        <WidthLimiterWrapper>
          <SizedContainer size={ContainerSizes.XXL}>
            <DataAttributeProvider
              dataAttributeStore={dataAttributeStore as DataAttributeStore}
              collectionID={CUSTOMER_COLLECTION}
              initialFormData={{
                ...currentCustomer?.data,
              }}
            >
              <Routes>
                <Route element={<Outlet />}>
                  <Route
                    index={true as any}
                    path="profile"
                    element={<ProfileTabPage />}
                  />
                  <Route path="info" element={<CustomerInfoTab />} />
                  <Route path="training-plans">
                    <Route path="" element={<TrainingPlansOverViewTab />} />
                    <Route
                      path=":trainingPlanID"
                      element={
                        <CreateCustomerTrainingPlan
                          formMethods={trainingPlanForm}
                        />
                      }
                    />
                  </Route>
                  <Route path="bookings" element={<CustomerBookingTab />} />
                  <Route
                    path="data-attribute"
                    element={
                      <DataAttributeFormBuilder
                        key={CUSTOMER_COLLECTION}
                        fieldsToIgnore={[]}
                        formID="data-attribute-form"
                        onSubmit={(data) => {
                          onSubmit(data);
                        }}
                      />
                    }
                  />

                  {/* DEFINE DYNAMIC TAB ROUTES */}
                  {dynamicTabs.map((attribute) => (
                    <Route
                      key={attribute.fieldID}
                      path={`${attribute.fieldID}`}
                      element={
                        <DataAttributeFormBuilder
                          key={`form-${attribute.fieldID}`}
                          formID="data-attribute-form"
                          renderOnlyFields={[attribute.fieldID]}
                          title={attribute.label}
                        />
                      }
                    />
                  ))}
                </Route>
              </Routes>
            </DataAttributeProvider>
          </SizedContainer>
        </WidthLimiterWrapper>
        <FixedControllersToolbar
          formID={
            isPlanDetailActive
              ? "training-plan-exercise-form"
              : "data-attribute-form"
          }
          disabled={false}
          isLoading={false}
          hasUnsavedChanges={true}
          keyString="booking-options-save-btn"
          title={getFixedToolbarTitle()}
          imageUrl={getFixedToolbarImage()}
        />
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "customerStore",
  "dataAttributeStore",
  "trainingPlanStore"
)(observer(CustomerDetailPage));
