import React from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import { ModalStore } from "stores/modal.store";
import RoleTypeStore from "stores/role.type.store";
import { RoleType } from "schemas/role.type.schema";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";

import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface RoleTypeListProps {
  roleTypes: RoleType[];
  handleClickRoleType: (roleType: RoleType, isNew: boolean) => void;
  isLoading?: boolean;
  roleTypeStore?: RoleTypeStore;
  modalStore?: ModalStore;
  organization: string | undefined;
}

const RoleTypeList = ({
  roleTypes,
  handleClickRoleType,
  isLoading = false,
  roleTypeStore,
  modalStore,
  organization,
}: RoleTypeListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.userRoles");

  const removeRoleType = (roleType: RoleType): void => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        if (roleType?._id == null) return;
        roleTypeStore?.removeRoleType({
          roleTypeId: roleType._id,
          filter: {
            organization,
          },
        });
      },
      confirmLabel: translate("buttons.deleteRoleTypeButton.confirmLabel"),
      title: translate("buttons.deleteRoleTypeButton.title"),
      description: translate("buttons.deleteRoleTypeButton.description"),
    });
  };

  return (
    <ListDataTable
      data={roleTypes || []}
      noDataMessage={translate("list.noDataMessage")}
      isLoading={isLoading}
      listDropdownMenu={{
        items: [
          {
            label: translate("list.listDropdownMenu.edit"),
            icon: faEdit,
            onClick: (roleType: RoleType) =>
              handleClickRoleType(roleType, false),
          },
          {
            label: translate("list.listDropdownMenu.delete"),
            icon: faTrash,
            onClick: (roleType: RoleType) => removeRoleType(roleType),
          },
        ],
        placement: "left",
      }}
      columns={[
        {
          child: (
            <TitleText tableHeader>{translate("list.roleType")}</TitleText>
          ),
          flex: 1,
        },
        {
          child: (
            <TitleText tableHeader>{translate("list.createdAt")}</TitleText>
          ),
          flex: 1,
        },
      ]}
      dataTableItemBuilder={(dataItem: RoleType) => {
        return {
          key: dataItem._id,
          children: [
            {
              child: <RunningText>{dataItem?.name}</RunningText>,
            },
            {
              child: (
                <RunningText>
                  {formatDate(dataItem?.system?.createdAt)}
                </RunningText>
              ),
            },
          ],
        } as any;
      }}
    />
  );
};

export default inject("roleTypeStore", "modalStore")(observer(RoleTypeList));
