import { RoleType } from "schemas/role.type.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpRoleTypeService extends GenericHttpClient<RoleType> {
  static _instance: HttpRoleTypeService;
  static getInstance(): HttpRoleTypeService {
    if (this._instance == null) {
      this._instance = new HttpRoleTypeService("/role-types");
    }
    return this._instance;
  }
}
