import { Role } from "schemas/role.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpRoleService extends GenericHttpClient<Role> {
  static _instance: HttpRoleService;
  static getInstance(): HttpRoleService {
    if (this._instance == null) {
      this._instance = new HttpRoleService("/roles");
    }
    return this._instance;
  }
}
