import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpOrganizationService } from "services/httpClients/http.organization.client";
import { Organization } from "schemas/organization.schemas/organization.schema";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";

class OrganizationStore {
  private stores: RootStore;

  // Properties
  private _organizationsDataList: PaginationDataList<Organization> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentOrganization: DataItem<Organization> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setOrganizations = (organizations: Organization[]): void => {
    this._organizationsDataList.data = organizations;
  };

  setCurrentOrganization = (organization: Organization | undefined): void => {
    this._currentOrganization.data = organization;
  };

  //! Getters
  get organizations(): PaginationDataList<Organization> | undefined {
    if (this._organizationsDataList == null) {
      return;
    }
    return this._organizationsDataList;
  }

  get currentOrganization(): DataItem<Organization> | undefined {
    if (this._currentOrganization == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentOrganization));
  }

  //! Methods
  fetchAndSetOrganizations = async (args: { filter?: any }): Promise<void> => {
    const { filter } = args;

    try {
      if (this._organizationsDataList.isLoading) {
        return;
      }

      this._organizationsDataList.isLoading = true;

      const organizations = await HttpOrganizationService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._organizationsDataList.pageIndex,
            itemsInPage: this._organizationsDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (organizations == null) {
        this._organizationsDataList.isLoading = false;
        return;
      }

      this.setOrganizations(organizations);

      this._organizationsDataList.isLoading = false;
    } catch (err) {
      this._organizationsDataList.isLoading = false;

      Logging.error({
        className: "OrganizationStore",
        methodName: "fetchAndSetOrganizations",
        message: "Could not get organizations",
        exception: err,
        showAlert: true,
      });
    }
  };

  getOrganization = async (args: { organizationId: string }): Promise<void> => {
    const { organizationId } = args;

    try {
      if (this._currentOrganization.isLoading) {
        return;
      }

      this._currentOrganization.isLoading = true;

      const organization = await HttpOrganizationService.getInstance().findOne({
        id: organizationId,
      });

      if (organization == null) {
        return;
      }

      this.setCurrentOrganization(organization);

      this._currentOrganization.isLoading = false;
    } catch (err) {
      Logging.error({
        className: "OrganizationStore",
        methodName: "getOrganization",
        message: "Could not get organization",
        exception: err,
        showAlert: true,
      });
    }
  };

  removeOrganization = async (args: {
    organizationId: string;
    filter?: any;
  }): Promise<void> => {
    const { organizationId, filter } = args;

    try {
      await HttpOrganizationService.getInstance().archiveOne({
        id: organizationId,
      });

      const organizations = await HttpOrganizationService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._organizationsDataList.pageIndex,
            itemsInPage: this._organizationsDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (organizations == null) {
        return;
      }

      this.setOrganizations(organizations);
    } catch (err) {
      Logging.error({
        className: "OrganizationStore",
        methodName: "removeOrganization",
        message: "Could not remove organization",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateOrganization = async (args: {
    organizationId: string;
    data: any;
    filter?: any;
  }): Promise<void> => {
    const { organizationId, data, filter } = args;

    try {
      await HttpOrganizationService.getInstance().updateOne({
        id: organizationId,
        data,
        query: filter,
      });

      const organizations = await HttpOrganizationService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._organizationsDataList.pageIndex,
            itemsInPage: this._organizationsDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (organizations == null) {
        return;
      }

      this.setOrganizations(organizations);
    } catch (err) {
      Logging.error({
        className: "OrganizationStore",
        methodName: "updateOrganization",
        message: "Could not update organization",
        exception: err,
        showAlert: true,
      });
    }
  };

  createOrganization = async (args: {
    data: any;
    filter?: any;
  }): Promise<Organization | undefined> => {
    const { data, filter } = args;

    try {
      const organization = await HttpOrganizationService.getInstance().create({
        data,
        query: filter,
      });

      const organizations = await HttpOrganizationService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._organizationsDataList.pageIndex,
            itemsInPage: this._organizationsDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (organizations == null) {
        return;
      }

      this.setOrganizations(organizations);

      return organization;
    } catch (err) {
      Logging.error({
        className: "OrganizationStore",
        methodName: "createOrganization",
        message: "Could not create organization",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default OrganizationStore;
