import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpRoleService } from "services/httpClients/http.role.client";
import { Role } from "schemas/role.schema";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";

class RoleStore {
  private stores: RootStore;

  // Properties
  private _rolesDataList: PaginationDataList<Role> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentRole: DataItem<Role> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setRoles = (roles: Role[]): void => {
    this._rolesDataList.data = roles;
  };

  setCurrentRole = (role: Role | undefined): void => {
    this._currentRole.data = role;
  };

  //! Getters
  get roles(): PaginationDataList<Role> | undefined {
    if (this._rolesDataList == null) {
      return;
    }
    return this._rolesDataList;
  }

  get currentRole(): DataItem<Role> | undefined {
    if (this._currentRole == null) {
      return;
    }
    return this._currentRole;
  }

  //! Methods
  fetchAndSetRoles = async (args: { filter?: any }): Promise<void> => {
    const { filter } = args;

    try {
      if (this._rolesDataList.isLoading) {
        return;
      }

      this._rolesDataList.isLoading = true;

      const roles = await HttpRoleService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._rolesDataList.pageIndex,
            itemsInPage: this._rolesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roles == null) {
        this._rolesDataList.isLoading = false;
        return;
      }

      this.setRoles(roles);

      this._rolesDataList.isLoading = false;
    } catch (err) {
      this._rolesDataList.isLoading = false;

      Logging.error({
        className: "RoleStore",
        methodName: "fetchAndSetRole",
        message: "Could not get roles",
        exception: err,
        showAlert: true,
      });
    }
  };

  removeRole = async (args: {
    roleId: string;
    filter?: any;
  }): Promise<void> => {
    const { roleId, filter } = args;

    try {
      await HttpRoleService.getInstance().archiveOne({ id: roleId });

      const roles = await HttpRoleService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._rolesDataList.pageIndex,
            itemsInPage: this._rolesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roles == null) {
        return;
      }

      this.setRoles(roles);
    } catch (err) {
      Logging.error({
        className: "RoleStore",
        methodName: "removeRole",
        message: "Could not remove role",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateRole = async (args: {
    roleId: string;
    data: any;
    filter?: any;
  }): Promise<void> => {
    const { roleId, data, filter } = args;

    try {
      await HttpRoleService.getInstance().updateOne({
        id: roleId,
        data,
        query: filter,
      });

      const roles = await HttpRoleService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._rolesDataList.pageIndex,
            itemsInPage: this._rolesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roles == null) {
        return;
      }

      this.setRoles(roles);
    } catch (err) {
      Logging.error({
        className: "RoleStore",
        methodName: "updateRole",
        message: "Could not update role",
        exception: err,
        showAlert: true,
      });
    }
  };

  createRole = async (args: { data: any; filter?: any }): Promise<void> => {
    const { data, filter } = args;

    try {
      await HttpRoleService.getInstance().create({
        data,
        query: filter,
      });

      const roles = await HttpRoleService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._rolesDataList.pageIndex,
            itemsInPage: this._rolesDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (roles == null) {
        return;
      }

      this.setRoles(roles);
    } catch (err) {
      Logging.error({
        className: "RoleStore",
        methodName: "createRole",
        message: "Could not create role",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default RoleStore;
