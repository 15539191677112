import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { inject, observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { toast } from "react-toastify";
import { ModalStore } from "../../../stores/modal.store";
import RoleTypeStore from "stores/role.type.store";

import ModalWrapper from "../modal.wrapper.component/modal.wrapper.component";
import ModalContent from "../modal.content.component/modal.content.component";
import ModalHeader from "../modal.header.component/modal.header.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";

interface AddRoleTypeModalProps {
  roleTypeStore?: RoleTypeStore;
  modalStore?: ModalStore;
}

const roleTypeFormSchema = yup.object().shape({
  name: yup.string().required(),
});

const AddRoleTypeModal = ({
  roleTypeStore,
  modalStore,
}: AddRoleTypeModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.modals");

  const [isLoading, setIsLoading] = useState(false);

  const { roleType, organization } = modalStore?.customData;

  const roleTypeFormMethods = useForm({
    resolver: yupResolver(roleTypeFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    values: {
      name: roleType?.name ?? "",
    },
  });

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
  } = roleTypeFormMethods;

  const onSubmit = async (form: { name: string }): Promise<void> => {
    setIsLoading(true);

    const data: any = {
      name: form.name,
    };

    if (organization) {
      data.organization = organization;
    }

    if (roleType?._id) {
      await roleTypeStore?.updateRoleType({
        roleTypeId: roleType._id,
        data,
        filter: {
          organization,
        },
      });
    } else {
      await roleTypeStore?.createRoleType({ data, filter: { organization } });
    }

    modalStore?.closeModal();

    setIsLoading(false);
  };

  return (
    <FormProvider {...roleTypeFormMethods}>
      <ModalWrapper>
        <ModalHeader
          title={
            roleType?._id
              ? translate("titles.updateRoleType")
              : translate("titles.newRoleType")
          }
        />

        <form
          id="add-role-type-modal-form"
          onSubmit={handleSubmit(onSubmit, () => {
            toast.error(translate("errors.onSubmitError"));
          })}
        >
          <ModalContent gap={20}>
            <FormWrapper gap={20}>
              <OutlinedTextInput
                required={true}
                label={translate("fields.typeName")}
                inputRef={register("name")}
                inputRefValue={getValues("name")}
                validationMessage={errors.name?.message?.toString()}
              />
            </FormWrapper>

            <Row justifyContent="flex-end">
              <FilledButton
                type="submit"
                color="primary"
                isLoading={isLoading}
                label={
                  roleType?._id
                    ? translate("buttons.update")
                    : translate("buttons.create")
                }
                disabled={!isValid || isSubmitting}
              />
            </Row>
          </ModalContent>
        </form>
      </ModalWrapper>
    </FormProvider>
  );
};

export default inject(
  "roleTypeStore",
  "modalStore"
)(observer(AddRoleTypeModal));
