import React, { useRef } from "react";
import "./main.modal.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import PlanExerciseSelectionModal from "../plan.exercise.selection.modal.component/plan.exercise.selection.modal.component";
import InviteTeamMemberModal from "../invite.team.member.modal/invite.team.member.modal";
import classNames from "classnames";
import ConfirmModal from "../confirm.modal.component/confirm.modal.component";
import ChangePasswordModal from "../change.password.modal/change.password.modal";
import SubscriptionModal from "../subscription.modal.component/subscription.modal.component";
import BillingAddressModal from "../billing.address.model/billing.address.model";
import TemplateTrainingPlanSelectionModal from "../template.training.plan.selection.modal/template.training.plan.selection.modal";
import TemplateLibraryModal from "../template.library.modal/template.library.modal";
import AskToNavigateModal from "../ask.to.navigate.modal.component/ask.to.navigate.modal.component";
import DataAttributeFormModal from "../data.attribute.form.modal.component/data.attribute.form.modal.component";
import { useClickedOutside } from "globals/helpers/hook.helper";
import DataAttributeCollectionFormModal from "../data.attribute.collection.form.modal.component/data.attribute.collection.form.modal.component";
import GlobalSearchModal from "../global.search.modal.component/global.search.modal.component";
import AssetSelectionModal from "../asset.selection.modal.component/asset.selection.modal.component";
import TaskManagementModal from "../task.management.modal.component/task.management.modal.component";
import AddOrganizationModal from "../add.organization.modal.component/add.organization.modal.component";
import AddStudioModal from "../add.studio.modal.component/add.studio.modal.component";
import AddRoleModal from "../add.role.modal.component/add.role.modal.component";
import AddRoleTypeModal from "../add.role.type.modal.component/add.role.type.modal.component";

interface MainModalProps {
  modalStore?: ModalStore;
}

const MainModal = ({ modalStore }: MainModalProps): JSX.Element => {
  const modalRef = useRef(null);

  // handle click outside for modal
  useClickedOutside(modalRef, () => {
    if (modalStore?.currentModal !== ModalType.INVISIBLE) {
      modalStore?.closeModal();
    }
  });

  const mainModalClassName = classNames({
    "main-modal-container": true,
    [`main-modal-container-${modalStore?.size ?? ModalSize.MEDIUM}`]: true,
    "main-modal-container-global-search":
      modalStore?.currentModal === ModalType.GLOBAL_SEARCH_MODAL,
  });

  const modalBlurClassName = classNames({
    "main-modal-background-container-blur": true,
    "main-modal-background-container-blur--algin-center":
      modalStore?.currentModal !== ModalType.GLOBAL_SEARCH_MODAL,
  });

  const getComponentForPath = (): JSX.Element => {
    switch (modalStore?.currentModal) {
      case ModalType.PLAN_EXERCISE_SELECTION_MODAL:
        return <PlanExerciseSelectionModal />;
      case ModalType.INVITE_TEAM_MEMBER_MODAL:
        return <InviteTeamMemberModal />;
      case ModalType.CONFIRM_MODAL:
        return <ConfirmModal />;
      case ModalType.CHANGE_PASSWORD_MODAL:
        return <ChangePasswordModal />;
      case ModalType.SUBSCRIPTION:
        return <SubscriptionModal />;
      case ModalType.EDIT_PAYMENT_ADDRESS_MODAL:
        return <BillingAddressModal />;
      case ModalType.TRAINING_PLAN_SELECTION_MODAL:
        return <TemplateTrainingPlanSelectionModal />;
      case ModalType.TEMPLATE_LIBRARY_MODAL:
        return <TemplateLibraryModal />;
      case ModalType.ASK_TO_NAVIGATE:
        return <AskToNavigateModal />;
      case ModalType.DATA_ATTRIBUTE_FORM_MODAL:
        return <DataAttributeFormModal />;
      case ModalType.DATA_ATTRIBUTE_COLLECTION_FORM_MODAL:
        return <DataAttributeCollectionFormModal />;
      case ModalType.GLOBAL_SEARCH_MODAL:
        return <GlobalSearchModal />;
      case ModalType.ASSET_SELECTION_MODAL:
        return <AssetSelectionModal />;
      case ModalType.TASK_MANAGEMENT_MODAL:
        return <TaskManagementModal />;
      case ModalType.ADD_ORGANIZATION_MODAL:
        return <AddOrganizationModal />;
      case ModalType.ADD_STUDIO_MODAL:
        return <AddStudioModal />;
      case ModalType.ADD_ROLE_MODAL:
        return <AddRoleModal />;
      case ModalType.ADD_ROLE_TYPE_MODAL:
        return <AddRoleTypeModal />;
      default:
        return <></>;
    }
  };

  if (modalStore?.currentModal === ModalType.INVISIBLE) {
    return <></>;
  }

  return (
    <div className="main-modal-background-container">
      <div className={modalBlurClassName}>
        <div className={mainModalClassName} ref={modalRef}>
          {modalStore?.currentModal !== ModalType.GLOBAL_SEARCH_MODAL && (
            <FontAwesomeIcon
              onClick={() => {
                modalStore?.closeModal();
              }}
              className="main-modal-container-close-icon"
              icon={faXmark}
            />
          )}
          {getComponentForPath()}
        </div>
      </div>
    </div>
  );
};

export default inject("modalStore")(observer(MainModal));
