import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { inject, observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { toast } from "react-toastify";

import { ModalStore } from "../../../stores/modal.store";
import StudioStore from "stores/studio.store";
import { studioSchema } from "schemas/studio.schemas/studio.schema";

import ModalWrapper from "../modal.wrapper.component/modal.wrapper.component";
import ModalContent from "../modal.content.component/modal.content.component";
import ModalHeader from "../modal.header.component/modal.header.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";

interface AddStudioModalProps {
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const AddStudioModal = ({
  modalStore,
  studioStore,
}: AddStudioModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "addStudioModal");

  const [isLoading, setIsLoading] = useState(false);
  const organizationId = modalStore?.customData.organizationID;

  const studioFormMethods = useForm({
    resolver: yupResolver(studioSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      _id: "",
      name: "",
      email: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting },
  } = studioFormMethods;

  const onSubmit = async (form: {
    _id: string;
    name: string;
    email: string;
  }): Promise<void> => {
    setIsLoading(true);

    const data: any = {
      name: form.name,
      email: form.email,
    };

    if (organizationId) {
      data.organization = organizationId;
    }

    const studio = await studioStore?.createStudio({ data });

    if (studio) {
      modalStore?.closeModal();
    }

    setIsLoading(false);
  };

  const _buildForm = (): JSX.Element => {
    return (
      <FormProvider {...studioFormMethods}>
        <ModalWrapper>
          <ModalHeader title={translate("createNewStudio")} />

          <form
            id="add-organization-modal-form"
            onSubmit={handleSubmit(onSubmit, () => {
              toast.error(translate("onSubmitError"));
            })}
          >
            <ModalContent gap={20}>
              <FormWrapper gap={20}>
                <OutlinedTextInput
                  required={true}
                  label={translate("name")}
                  inputRef={register("name")}
                />
                <OutlinedTextInput
                  required={true}
                  label={translate("email")}
                  inputRef={register("email")}
                />
              </FormWrapper>
              <Row justifyContent="flex-end">
                <FilledButton
                  type="submit"
                  color="primary"
                  isLoading={isLoading}
                  label={translate("create")}
                  disabled={!isDirty || isSubmitting}
                />
              </Row>
            </ModalContent>
          </form>
        </ModalWrapper>
      </FormProvider>
    );
  };

  return _buildForm();
};

export default inject("modalStore", "studioStore")(observer(AddStudioModal));
