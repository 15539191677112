import React from "react";
import "./card.component.scss";
import { ImageSizes } from "../../../../globals/enums/global.enum";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import Image from "components/image.component/image.component";
import ImagePlaceholder from "components/general.compoenents/image.placeholder.component/image.placeholder.component";

interface CardComponentProps {
  title: string;
  value?: string;
  imageUrl?: string;
  icon?: any;
  onClick?: () => void;
  onDelete?: () => void;
}

const CardComponent = ({
  title,
  value,
  imageUrl,
  icon,
  onClick,
  onDelete,
}: CardComponentProps): JSX.Element => {
  return (
    <Row className="card-wrapper" gap={10} onClick={onClick}>
      {imageUrl ? (
        <Image
          className="card-wrapper-image"
          imageUrl={imageUrl}
          size={ImageSizes.S}
        />
      ) : (
        <ImagePlaceholder label={title} icon={icon} />
      )}
      <Column justifyContent="center">
        <TitleText className="title-text mb-5">{title}</TitleText>
        {value && <RunningText className="value-text">{value}</RunningText>}
      </Column>
      <FontAwesomeIcon
        icon={faTrashAlt}
        onClick={(e: any) => {
          e.stopPropagation();
          if (onDelete) onDelete();
        }}
      />
    </Row>
  );
};

export default CardComponent;
