import React, { useState, useEffect, useRef } from "react";
import "./filter.component.scss";
import classNames from "classnames";
import {
  faCirclePlus,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FilterValue } from "globals/helpers/storage.helper";
import { useClickedOutside } from "globals/helpers/hook.helper";

import ListDropdownMenu from "components/input.components/dropdown.components/list.dropdown.component/list.dropdown.component";
import SelectDropDownList, {
  SelectItem,
} from "components/list.components/select.dropdown.list.component/select.dropdown.list.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import OutlinedDateRange from "components/date.components/date.range.component/date.range.component";

interface FilterProps {
  language: any;
  initialFilterData?: FilterValue[] | undefined;
  filterOptions: FilterValue[];
  isDisableFilter: boolean;
  isClearFilter: boolean;
  onFilter: (options: FilterValue[]) => Promise<void>;
}

const Filter = ({
  language,
  initialFilterData,
  filterOptions,
  isDisableFilter,
  isClearFilter,
  onFilter,
}: FilterProps): JSX.Element => {
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<FilterValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterValue[]>(
    initialFilterData ?? []
  );
  const [startDateValue, setStartDateValue] = useState<string>("start");
  const [endDateValue, setEndDateValue] = useState<string>("end");
  const [isDateRange, showDateRange] = useState<boolean>(false);

  const dateRangeRef = useRef<HTMLDivElement | null>(null);

  useClickedOutside(dateRangeRef, () => {
    if (!dateRangeRef) return;

    showDateRange(false);
  });

  useEffect(() => {
    if (isClearFilter || isDisableFilter) {
      setSelectedColumns([]);
    }
  }, [isClearFilter, isDisableFilter]);

  useEffect(() => {
    initializeFilters();
  }, [initialFilterData]);

  const initializeFilters = (): void => {
    // set initial date labels for DateRange filter
    const dateFilter = initialFilterData?.find(
      (filter: any) => filter.columnValue === "system.createdAt"
    );
    if (dateFilter?.values.length) {
      const { startDate, endDate } = JSON.parse(dateFilter.values[0].value);

      if (startDate) setStartDateValue(startDate);
      if (endDate) setEndDateValue(endDate);
    }

    // set initial data for filters (without DateRange filter)
    const dataFilters = initialFilterData?.filter(
      (filter: any) => filter.columnValue !== "system.createdAt"
    );

    if (dataFilters?.length) {
      const columns: any[] = [];

      // set options for selected filter columns
      dataFilters.forEach((filter: FilterValue) => {
        // define options for every filter column from dataFilters
        const options = filterOptions.find(
          (item: FilterValue) => item.columnName === filter.columnName
        );

        if (options) {
          columns.push(options);
        }
      });

      setSelectedColumns(columns);
    }
  };

  // convert initial options to required type
  const initialSelectedOptions = initialFilterData?.map((option) => ({
    columnName: option.columnName,
    columnValue: option.columnValue,
    values: option.values.map((v) => ({ label: v.name, value: v.value })),
  }));

  const columnsDropDownItems = filterOptions.map((option) => ({
    label: option.columnName,
    onClick: () => {
      setIsDropDownOpened(false);
      if (
        !selectedColumns.find((col) => col.columnValue === option.columnValue)
      ) {
        setSelectedColumns([...selectedColumns, option]);
      }
    },
  }));

  const onSelectFilter = async (
    columnName: string,
    columnValue: string,
    values: SelectItem[]
  ): Promise<void> => {
    const newOption: FilterValue = {
      columnName,
      columnValue,
      values: values.map((v) => {
        return {
          name: v.label,
          value: v.value,
        };
      }),
    };

    const newOptions = [...selectedOptions];

    const columnIndex = newOptions.findIndex(
      (option) => option.columnName === columnName
    );

    if (columnIndex !== -1) {
      newOptions[columnIndex] = newOption;
    } else {
      newOptions.push(newOption);
    }

    setSelectedOptions(newOptions);
    await onFilter(newOptions);
  };

  const onCloseFilter = async (columnValue: string): Promise<void> => {
    const options = selectedOptions.filter(
      (column) => column.columnValue !== columnValue
    );

    const columns = selectedColumns.filter(
      (column) => column.columnValue !== columnValue
    );

    setSelectedOptions(options);
    setSelectedColumns(columns);

    await onFilter(options);
  };

  const handleDateRangeChange = async (
    column: any,
    range: { startDate: string; endDate: string }
  ): Promise<void> => {
    setStartDateValue(range.startDate);
    setEndDateValue(range.endDate);

    const items = [{ label: "createdAt", value: JSON.stringify(range) }];
    await onSelectFilter(column.columnName, column.columnValue, items);
  };

  const _buildDateRangeFilter = (column: any): JSX.Element => {
    return (
      <div
        ref={dateRangeRef}
        key={column.columnValue}
        className="date-range-filter-wrapper"
      >
        <div className="date-range-filter-container">
          <FilledButton
            className="date-range-label-button"
            label={startDateValue}
            color="light"
            onClick={(e) => {
              e.stopPropagation();
              showDateRange(!isDateRange);
            }}
          />
          <span className="date-range-label">to</span>
          <FilledButton
            className="date-range-label-button"
            label={endDateValue}
            color="light"
            onClick={(e) => {
              e.stopPropagation();
              showDateRange(!isDateRange);
            }}
          />
          <IconButton
            className="date-range-close-button"
            icon={faXmark}
            onClick={async () => await onCloseFilter(column.columnValue)}
          />
        </div>
        {isDateRange && (
          <OutlinedDateRange
            calendarSize="normal"
            localization={language?.value}
            onChange={async (value) =>
              await handleDateRangeChange(column, value)
            }
          />
        )}
      </div>
    );
  };

  const filterClassName = classNames({
    "default-filter-container": true,
    "default-filter-container--disabled": isDisableFilter,
  });

  const filterButtonWrapperClassName = classNames({
    "filter-button-wrapper": true,
    "filter-button-wrapper--disabled": isDisableFilter,
  });

  const multiSelects = selectedColumns.map((column, index) => {
    const optionsToDisplay = column.values.map((v) => ({
      label: v.name,
      value: v.value,
    }));

    // get initial options of the selected column
    const selectedOption = initialSelectedOptions?.find(
      (item) => item.columnValue === column.columnValue
    );

    if (column.columnValue === "system.createdAt") {
      return _buildDateRangeFilter(column);
    }

    return (
      <SelectDropDownList
        key={column.columnValue + index.toString()}
        options={optionsToDisplay}
        onClose={async () => await onCloseFilter(column.columnValue)}
        selectDropDownListButtonLabel={column.columnName}
        initialSelectedOptions={selectedOption?.values}
        onSelect={async (items) =>
          await onSelectFilter(column.columnName, column.columnValue, items)
        }
        selectDropDownListButton={(props) => (
          <div className={filterButtonWrapperClassName}>
            <FilledButton color="light" {...props} />
          </div>
        )}
      />
    );
  });

  return (
    <div className={filterClassName}>
      {selectedColumns.length > 0 ? (
        <>
          {multiSelects}
          <ListDropdownMenu
            placement="right"
            items={columnsDropDownItems}
            colorScheme="secondary"
            dropdownMenuButton={(props) => (
              <div className={filterButtonWrapperClassName}>
                <IconButton
                  disabled={isDisableFilter}
                  icon={faPlus}
                  {...props}
                />
              </div>
            )}
          />
        </>
      ) : (
        <ListDropdownMenu
          placement="right"
          items={columnsDropDownItems}
          colorScheme="secondary"
          onDropDownToggle={(value) => setIsDropDownOpened(value)}
          dropdownButtonClasses="font-weight-bold"
          dropdownMenuButton={(props) => (
            <div className={filterButtonWrapperClassName}>
              <FilledButton
                disabled={isDisableFilter}
                label="Add Filter"
                color="light"
                icon={isDropDownOpened ? faChevronUp : faCirclePlus}
                isIconBehind={isDropDownOpened}
                {...props}
              />
            </div>
          )}
        />
      )}
    </div>
  );
};

export default Filter;
