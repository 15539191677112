import React, { ReactNode } from "react";
import Column from "../column.component/column.component";
import "./form.wrapper.component.scss";
import classNames from "classnames";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface FormWrapperProps {
  title?: string;
  children: ReactNode;
  className?: string;
  actions?: JSX.Element | JSX.Element[];
  noPadding?: boolean;
  gap?: number;
}

const FormWrapper = ({
  title,
  children,
  className,
  actions,
  noPadding,
  gap,
}: FormWrapperProps): JSX.Element => {
  const FormWrapperClassName = classNames(
    {
      "form-wrapper": true,
      "form-wrapper--no-padding": noPadding,
    },
    className
  );

  const headerClassName = classNames({
    "form-wrapper-header": true,
  });

  return (
    <Column className={FormWrapperClassName}>
      {(title != null || actions != null) && (
        <div className={headerClassName}>
          <RunningText className="bold">{title}</RunningText>
          {actions}
        </div>
      )}
      <div className="form-wrapper-content" style={{ gap }}>
        {children}
      </div>
    </Column>
  );
};

export default FormWrapper;
