import React from "react";
import "./organization.sidebar.component.scss";
import classNames from "classnames";
import { Navigate, useParams } from "react-router";
import { inject, observer } from "mobx-react";

import { useSidebar } from "../sidebar.provider.component/sidebar.provider.component";
import OrganizationStore from "stores/organization.store";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faHome,
  faUserGroupCrown,
  faGear,
} from "@fortawesome/pro-regular-svg-icons";

import GymoLogo from "components/general.compoenents/gymo.logo.component/gymo.logo.component";
import SidebarNavigationItem from "../sidebar.item.comonent/sidebar.item.component";
import Row from "components/general.compoenents/row.component/row.component";
import LoggedInUserDropdown from "components/input.components/dropdown.components/logged.in.user.dropdown.component/logged.in.user.dropdown.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import Column from "components/general.compoenents/column.component/column.component";
import SidebarCollapsibleItem from "../sidebar.collapsible.item.component/sidebar.collapsible.item.component";

interface OrganizationSidebarProps {
  className?: string;
  organizationStore?: OrganizationStore;
}

const OrganizationSidebar = ({
  className,
  organizationStore,
}: OrganizationSidebarProps): JSX.Element => {
  const { organizationID } = useParams();
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization.sidebar");
  const { isCollapsed, toggleCollapse } = useSidebar();

  const organization = organizationStore?.currentOrganization?.data;

  if (organizationID == null) {
    return <Navigate to="/" replace />;
  }

  const getPath = (path: string): string => {
    return `/organizations/${organizationID}/${path}`;
  };

  const organizationSidebarClassName = classNames(
    {
      "organization-sidebar-wrapper": true,
      "organization-sidebar-wrapper--collapsed": isCollapsed,
    },
    className
  );

  const sidebarHeaderLogoClassName = classNames({
    "sidebar-header-logo": true,
    "sidebar-header-logo--collapsed": isCollapsed,
  });

  return (
    <div className={organizationSidebarClassName} id="organization-sidebar">
      <Row justifyContent="space-between">
        <GymoLogo
          className={sidebarHeaderLogoClassName}
          size={24}
          lettering={!isCollapsed}
        />
      </Row>

      <Row className="organization-title">
        {isCollapsed ? (
          <FontAwesomeIcon
            className="sidebar-header-collapse-icon"
            icon={faArrowLeft}
            onClick={toggleCollapse}
          />
        ) : (
          <Row gap={10} alignItems="center">
            <FontAwesomeIcon
              className="sidebar-header-collapse-icon"
              icon={faArrowRight}
              onClick={toggleCollapse}
            />
            <TitleText>{organization?.name}</TitleText>
          </Row>
        )}
      </Row>

      <Column justifyContent="flex-start">
        <SidebarCollapsibleItem
          title={translate("generalSettings")}
          icon={faGear}
          paths={["settings"]}
        >
          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faGear}
            path={getPath("settings")}
          >
            {translate("generalSettings")}
          </SidebarNavigationItem>

          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faGear}
            path={getPath("settings")}
          >
            {translate("generalSettings")}
          </SidebarNavigationItem>
        </SidebarCollapsibleItem>

        <SidebarCollapsibleItem
          title={translate("organization")}
          icon={faHome}
          paths={["organization"]}
        >
          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faHome}
            path={getPath("organization")}
          >
            {translate("organization")}
          </SidebarNavigationItem>
        </SidebarCollapsibleItem>

        <SidebarCollapsibleItem
          title={translate("studioGroups")}
          icon={faGear}
          paths={["studio-groups"]}
        >
          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faGear}
            path={getPath("studio-groups")}
          >
            {translate("studioGroups")}
          </SidebarNavigationItem>
        </SidebarCollapsibleItem>

        <SidebarCollapsibleItem
          title={translate("userRoles")}
          icon={faGear}
          paths={["user-roles", "permission-groups"]}
        >
          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faGear}
            path={getPath("user-roles")}
          >
            {translate("userRoles")}
          </SidebarNavigationItem>

          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faGear}
            path={getPath("permission-groups")}
          >
            {translate("permissionGroups")}
          </SidebarNavigationItem>
        </SidebarCollapsibleItem>

        <SidebarCollapsibleItem
          title={translate("users")}
          icon={faUserGroupCrown}
          paths={["users"]}
        >
          <SidebarNavigationItem
            className="organization-sidebar-item"
            icon={faUserGroupCrown}
            path={getPath("users")}
          >
            {translate("users")}
          </SidebarNavigationItem>
        </SidebarCollapsibleItem>
      </Column>

      <LoggedInUserDropdown className="user-dropdown" />
    </div>
  );
};

export default inject("organizationStore")(observer(OrganizationSidebar));
