import { Logging } from "globals/helpers/logging.helper";
import { Studio } from "schemas/studio.schemas/studio.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpStudioService extends GenericHttpClient<Studio> {
  static _instance: HttpStudioService;
  static getInstance(): HttpStudioService {
    if (this._instance == null) {
      this._instance = new HttpStudioService("/studios");
    }
    return this._instance;
  }

  createInitialStudio = async (): Promise<Studio | undefined> => {
    try {
      const response = await this.post("/studios", {});
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpStudioService",
        methodName: "createInitialStudio",
        message: "Studio konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createNewStudio = async (data: any): Promise<Studio | undefined> => {
    try {
      const response = await this.post("/studios/new", data);
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpStudioService",
        methodName: "createNewStudio",
        message: "Create a new studio error",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateCurrentStudio = async (studio: Studio): Promise<Studio | undefined> => {
    try {
      const response = await this.put("/studios/me", studio);
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpStudioService",
        methodName: "updateCurrentStudio",
        message: "Studio konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  getVerifyCode = async (): Promise<any | undefined> => {
    try {
      const response = await this.get("/studios/verify-code");
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpStudioService",
        methodName: "getVerifyCode",
        message: "Verify Code konnte nicht abgerufen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  regenerateVerifyCode = async (): Promise<any | undefined> => {
    try {
      const response = await this.put("/studios/verify-code");
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpStudioService",
        methodName: "getVerifyCode",
        message: "Verify Code konnte nicht abgerufen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  publishBrandedApp = async (): Promise<void> => {
    return await this.post("/studios/publish-app");
  };
}
