import React from "react";
import "./modal.wrapper.component.scss";
import classNames from "classnames";

interface ModalWrapperProps {
  children: React.ReactNode;
  className?: string;
}

const ModalWrapper = ({
  children,
  className,
}: ModalWrapperProps): JSX.Element => {
  const modalWrapperClassName = classNames(
    {
      "default-modal-wrapper": true,
    },
    className
  );

  return <div className={modalWrapperClassName}>{children}</div>;
};

export default ModalWrapper;
