import React, { useState, useEffect } from "react";
import "./organization.user.role.page.scss";
import { useParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import RoleStore from "stores/role.store";
import RoleTypeStore from "stores/role.type.store";
import { ResourceType } from "globals/enums/global.enum";

import RolePage from "./components/role.page.component/role.page.component";
import RoleTypePage from "./components/role.type.page.component/role.type.page.component";

import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import Headline from "components/text.components/headline.component/headline.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";

enum TabType {
  ROLES = "ROLES",
  ROLE_TYPES = "ROLE_TYPES",
}

interface OrganizationUserRolePageProps {
  roleStore?: RoleStore;
  roleTypeStore?: RoleTypeStore;
}

const OrganizationUserRolePage = ({
  roleStore,
  roleTypeStore,
}: OrganizationUserRolePageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "organization");
  const { organizationID } = useParams();

  const roles = roleStore?.roles?.data ?? [];
  const roleTypes = roleTypeStore?.roleTypes?.data ?? [];

  const [pageTab, setPageTab] = useState(TabType.ROLES);

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    roleStore?.fetchAndSetRoles({
      filter: {
        resourceType: ResourceType.ORGANIZATION,
        resource: organizationID,
      },
    });
    roleTypeStore?.fetchAndSetRoleTypes({
      filter: {
        organization: organizationID,
      },
    });
  };

  const _buildPageTabs = (): JSX.Element => {
    return (
      <LinkTabs
        className="user-role-tabs"
        tabs={[
          <Tab
            label={translate("userRoles.tabs.roles")}
            key="role-tab"
            active={pageTab === TabType.ROLES}
            onClick={() => {
              setPageTab(TabType.ROLES);
            }}
          />,
          <Tab
            label={translate("userRoles.tabs.roleTypes")}
            key="role-type-tab"
            active={pageTab === TabType.ROLE_TYPES}
            onClick={() => {
              setPageTab(TabType.ROLE_TYPES);
            }}
          />,
        ]}
      ></LinkTabs>
    );
  };

  const _buildPageTable = (): JSX.Element => {
    if (pageTab === TabType.ROLES) {
      return (
        <RolePage
          roles={roles}
          roleTypes={roleTypes}
          organization={organizationID}
        />
      );
    }

    if (pageTab === TabType.ROLE_TYPES) {
      return (
        <RoleTypePage roleTypes={roleTypes} organization={organizationID} />
      );
    }

    return <></>;
  };

  return (
    <PageContainer className="user-role-page-container">
      <WidthLimiterWrapper>
        <Row>
          <Headline className="mt-20 mb-20">User Roles</Headline>
        </Row>
        <Row justifyContent="flex-start">{_buildPageTabs()}</Row>
        {_buildPageTable()}
      </WidthLimiterWrapper>
    </PageContainer>
  );
};

export default inject(
  "roleStore",
  "roleTypeStore"
)(observer(OrganizationUserRolePage));
